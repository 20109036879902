import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useCreateSujet(idAgence, corps, objet, personne, categorie, isReadOnly, tabEtats) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    let jsonCreationSujet = {
        idAgence: idAgence,
        // isPublie: false,
        tabEtats : tabEtats,
        isLectureSeule : isReadOnly,
        nbVues: 0,
        objet: objet,
        corps: corps,
        auteur: {
            personne : personne
        },
        categorie: categorie
    }

    return useMutation((tabEtats) => {
        return api.post('sujetForum', {
            json: {...jsonCreationSujet,
                tabEtats : tabEtats
            }
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries("sujetForumByCategories"+categorie.libelle);
            queryClient.invalidateQueries("categorieSujet");
            snapAuth.websocket.send("sujetForumByCategories"+categorie.libelle)
            snapAuth.websocket.send("categorieSujet")

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la création de sujet ", err.message);
        },
    });
}