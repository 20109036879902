import {Card, Button, Modal} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import React, {useMemo, useRef, useState} from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import "flatpickr/dist/plugins/monthSelect/style.css"
import {useSnapshot} from "valtio";
import facturationProxy from "../../../proxies/facturation";
import invitationsProxy from "../../../proxies/invitations";
import { ReactComponent as SuiviEmailBlack } from "@assets/icons/suiviEmailV2.svg";
import useGetDetailReversion from "../../../requests/facturation/reversion/useGetDetailReversion";
import auth from "../../../services/auth";
import useGetExportReversion from "../../../requests/facturation/reversion/useGetExportReversion";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import DetailContributionsModal from "./DetailContributionsModal";


function DetailReversionView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const snapFacturation = useSnapshot(facturationProxy);
    const [showModalContribution, setShowModalContribution] = useState(false);
    const getDetails = useGetDetailReversion(snapFacturation?.reversion?.id);
    const colSearch = useMemo(() => {
        return {
            'type': "'charge','refund'",
        };
    }, []);
    const urlExportReversion = useGetExportReversion({
        onSuccess: (data) => {
            window.open(data.url, '_blank');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const columns = useMemo(() => [
        // {
        //     Header: () => null,
        //     id: 'wrench',
        //     Cell: ({ row }) => {
        //         return <span
        //             style={{fill: "#211f1c"}}
        //             title="Télécharger la réversion"
        //             onClick={() => {
        //
        //             }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><i className="fa fa-2x fa-download"></i></span>
        //     },
        //     width: 60,
        //     disableResizing: true,
        //     disableSearch: true,
        //     noWrap: true,
        // },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Acheteur',
            Cell: ({ row }) => {
                return <>
                    <p className="m-0 p-0">
                        {row.original?.facture?.correspondantInvitation?.destinataire.personne.prenom + " " + row.original?.facture?.correspondantInvitation?.destinataire.personne.nom }
                    </p>
                    <a className="m-0 p-0 small" target="_blank" href={row.original?.facture?.url}>{row.original?.facture?.numero}</a>
                </>
            },
            accessor: "facture.correspondantInvitation.destinataire.personne.prenomNom",
            minWidth: 200,
            maxWidth: 200,
        },
        {
            Header: 'Personnes concernées',
            accessor: 'facture.correspondantInvitation.tabAccompagnants.destinataire.personne.prenomNom',
            minWidth: 200,
            noWrap:true,
            Cell: ({ row }) => {
                let accompagnants = [];
                if (row.original?.facture?.tabArticles){
                    accompagnants = row.original?.facture?.tabArticles.map(e => e?.price_data?.product_data?.name?.split(" pour ")[1]+"")
                    // if (accompagnants.find((e) => row.original?.correspondantInvitation.destinataire.personne.prenom+" "+row.original?.correspondantInvitation.destinataire.personne.nom+" " == e)){
                    //     accompagnants.splice(accompagnants.indexOf(row.original?.correspondantInvitation.destinataire.personne.prenom+" "+row.original?.correspondantInvitation.destinataire.personne.nom+" "), 1)
                    // }
                }
                return accompagnants.join(',\n');
            }
        },
        {
            Header: 'Entreprise',
            accessor: 'facture.correspondantInvitation.destinataire.tabEntreprises.nom',
            minWidth: 200,
            noWrap:true,
            Cell: ({ row }) => {
                let entreprises = [];
                if (row.original?.facture?.correspondantInvitation?.destinataire?.tabEntreprises){
                    entreprises = Object.values(row.original?.facture?.correspondantInvitation?.destinataire?.tabEntreprises).map(e => e?.nom+"")
                }
                return entreprises.join(',\n');
            }
        },
        {
            Header: 'Montant',
            accessor: 'montant',
            Cell: ({ row }) => {
                return  row?.original?.montant.toString().replace(".", ",")+"€"
            },
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Frais',
            accessor: 'frais',
            Cell: ({ row }) => {
                return  row?.original?.frais.toString().replace(".", ",")+"€"
            },
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Date création',
            accessor: 'dateCreation',
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Id invitation',
            minWidth: 150,
            maxWidth: 150,
            accessor: 'facture.invitation.id',
            Cell: ({ row }) => {
                return <>
                    <span  className="me-2">{row?.original?.facture?.invitation?.id}</span>
                    <span title="Suivi de l'invitation" className="cursor-pointer" onClick={() => {
                        invitationsProxy.showSuiviInvitation(row?.original?.facture?.invitation)
                    }
                    }><SuiviEmailBlack className="h-25px w-25px" /></span>
                </>

            }

        },
        {
            Header: 'Type évènement',
            minWidth: 200,
            maxWidth: 200,
            accessor: 'facture.invitation.type.libelle',

        },
        {
            Header: 'Date évènement',
            minWidth: 150,
            maxWidth: 150,
            accessor: 'invitation.tabPeriodes',
            Cell: ({ row }) => {
                if (row?.original?.facture?.invitation?.tabPeriodes){

                    return (Object.values(row?.original?.facture?.invitation?.tabPeriodes)[0].debut).split(" ")[0]
                }else {
                    return  ""
                }
            }

        },
        {
            Header: 'type',
            accessor: 'type',
            searchOperator:'IN',
            hidden:true
        },

    ], []);

    if (!snapFacturation.reversion || getDetails.isLoading || getDetails.isError){
        return "Erreur de chargement..."
    }

    // console.log(getDetails)
    let nbTransactions = getDetails?.data?.data.filter(item => item.type == 'charge' || item.type == 'refund').length;
    const reducer = (accumulator, curr) => accumulator + curr;
    let montantDesVentes = getDetails?.data?.data.filter(item => item.type == 'charge')?.map(details => details.montant)?.reduce(reducer, 0)?.toFixed(2);
    let montantDesComission = getDetails?.data?.data.filter(item => item.type == 'charge')?.map(details => details.frais)?.reduce(reducer, 0)?.toFixed(2);
    let montantDesRembourssements = getDetails?.data?.data.filter(item => item.type == 'refund')?.map(details => details.montant)?.reduce(reducer, 0)?.toFixed(2);
    let montantDesContributions = getDetails?.data?.data.filter(item => item.type == 'contribution')?.map(details => details.montant)?.reduce(reducer, 0)?.toFixed(2);
    let nbContributions = getDetails?.data?.data.filter(item => item.type == 'contribution')?.length;
    let revenuBrutOrganisateur = (montantDesVentes - montantDesComission - montantDesRembourssements).toFixed(2);
    let dateDeVirement = snapFacturation?.reversion?.dateCreation;
    let tauxTva = auth?.parametreFacturation?.tauxTva ? auth?.parametreFacturation?.tauxTva : 20;
    let taxeDue = ((revenuBrutOrganisateur/(100+tauxTva))*tauxTva).toFixed(2);
    let baseHT = (revenuBrutOrganisateur - taxeDue).toFixed(2)
    return <>
        <Card>
            <Card.Body>
                <div class="d-flex">
                    <div className="table-responsive m-auto w-500px ">
                        <table className="table table-rounded table-striped border">
                            <thead>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                <th className="text-center" colSpan={2}>
                                    Tableau récapitulatif
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="p-3">Nombre de transactions</td>
                                <td className="p-3">{nbTransactions}</td>
                            </tr>
                            <tr>
                                <td className="p-3">Montant des ventes</td>
                                <td className="p-3">{montantDesVentes.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Total TTC des commissions</td>
                                <td className="p-3">
                                    {montantDesComission.toString().replace(".", ",")}€
                                </td>
                            </tr>
                            <tr>
                                <td className="p-3">Remboursements</td>
                                <td className="p-3">{montantDesRembourssements.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Revenu brut de l'organisateur</td>
                                <td className="p-3">{revenuBrutOrganisateur.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Contributions pour le climat</td>
                                <td className="p-3">{nbContributions > 0 ?
                                    (montantDesContributions)
                                    :
                                    0}€ {nbContributions > 0 && <span
                                    onClick={() => {
                                        setShowModalContribution(true)
                                    }}
                                    className="btn btn-primary fa fa-info m-0 p-2"></span>}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive m-auto w-500px ">
                        <table className="table table-rounded table-striped border">
                            <thead>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                <th className="text-center" colSpan={2}>
                                    Informations TVA
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="p-3">Base de revenu TTC</td>
                                <td className="p-3">{revenuBrutOrganisateur.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Base revenu HT</td>
                                <td className="p-3">{baseHT.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Taxe due</td>
                                <td className="p-3">{taxeDue.toString().replace(".", ",")}€</td>
                            </tr>
                            <tr>
                                <td className="p-3">Taux de TVA</td>
                                <td className="p-3">{tauxTva.toString().replace(".", ",")}%</td>
                            </tr>
                            <tr>
                                <td className="p-3">Date du virement</td>
                                <td className="p-3">{dateDeVirement}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <Button
                        onClick={() => {
                            urlExportReversion.mutate(snapFacturation?.reversion?.id)
                        }}
                        variant="secondary"
                    >
                        Télécharger les détails de la réversion
                    </Button>
                </div>
                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url={'reversionPaiement/'+snapFacturation?.reversion?.id+'/detail'}
                    keys={['reversionPaiement'+snapFacturation?.reversion?.id]}
                    columns={columns}
                    search={searchFilter}
                    colSearch={colSearch}
                />
                <DetailContributionsModal showModal={showModalContribution} setShowModal={setShowModalContribution} data={getDetails?.data?.data.filter(item => item.type == 'contribution')}/>
                {/*<div className='d-flex mt-3'>*/}
                {/*    <Button*/}
                {/*        variant='secondary'*/}
                {/*        className='ms-auto'*/}
                {/*        onClick={() => {*/}
                {/*            // facturation.showExportFacturation(urlExport?.data)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <FontAwesomeIcon icon={faFileDownload} className='me-2' />*/}
                {/*        Exporter*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Card.Body>
        </Card>
    </>;
}

export default DetailReversionView;