import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import RangeSlider from "../../../components/RangeSlider/RangeSlider";
import auth from "../../../services/auth";

const ReglagesStep = forwardRef((_, ref) => {
    const speedSnap = useSnapshot(speedBusinessProxy);
    const {
        register, handleSubmit, reset, control,
        formState: { errors },
    } = useForm({
        defaultValues: speedBusinessProxy.speedBusiness,
    });
    const arrayNbpremier = [
        2, 3, 4, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97
    ];
    const [nbPersonne, setNbPersonne] = useState(80);
    const [nom, setNom] = useState("");
    const [tempsParPersonne, setTempsParPersonne] = useState(3);
    const [tempsPourChangerPlace, setTempsPourChangerDePlace] = useState(3);
    const [nbTours, setNbTours] = useState(4);
    const [duree, setDuree] = useState(120);
    const [dureeEntracte, setDureeEntracte] = useState(0);
    const [privilegieDuree, setPrivilegieDuree] = useState(false);
    const [reglagesAuto, setReglagesAuto] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableMaximum, setTableMaximum] = useState(20);
    const [nbPersonneMaxParTable, setNbPersonneMaxParTable] = useState(10);
    const [stateLimited, setStateLimited] = useState(false);
    const [result, setResult] = useState(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, reglagesAuto, tableMaximum, nbPersonneMaxParTable, setStateLimited));

    useEffect(() => {
        //-- calcul et mise a jour du tableau récap des solutions
        if (privilegieDuree && !reglagesAuto){
            setResult(nbToursEnFonctionDeLaDuree(nbPersonne, duree, tableMaximum, nbPersonneMaxParTable, setStateLimited))
        }else if (!privilegieDuree && !reglagesAuto){
            setResult(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, false, tableMaximum, nbPersonneMaxParTable, setStateLimited));
        }else{
            setResult(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, true, tableMaximum, nbPersonneMaxParTable, setStateLimited))
        }
    }, [nbPersonne, tempsParPersonne, tempsPourChangerPlace, nbTours, privilegieDuree, duree, tableMaximum, nbPersonneMaxParTable, reglagesAuto, dureeEntracte])

    useEffect(() => {
        reset(speedBusinessProxy.speedBusiness ?? {
            idInvitation: null,
            auteur: auth.utilisateur,
            agence: auth.agence,
            dateCreation: Date.now(),
            dateProgrammationEnvoiEmail: null,
            nom: "",
            dureeChangementPlace: tempsPourChangerPlace,
            dureeEntracte:dureeEntracte,
            dureeParoleParPersonne: tempsParPersonne,
            dureeTotale: duree,
            nombreTours: nbTours,
            nombrePersonnesMaxParTable: nbPersonneMaxParTable,
            nombrePersonnesPrevues: nbPersonne,
            calculAutoDureeTotale: reglagesAuto,
            utilisationServiceSms: false,
            id:null,
            //todo objet par default
        });
    }, [reset, speedBusinessProxy.speedBusiness]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                try {
                    //todo faire la mutation ici

                    // const result = await changeMail.mutateAsync(data);
                    // mailsProxy.mail = result;
                    done = true;
                } catch {}
            })();
            return done;
        },
    }));

    function calcNbPersonneParTable(nbPersonnes = 160){
        let arrayNbTablesKeyPersonnePerTableValue = {};
        let lastTmp = null;
        arrayNbpremier.forEach((nbPremier,index) => {
            if (nbPersonnes <= (nbPremier*nbPremier)){
                let tmp = Math.ceil(nbPersonnes/nbPremier)
                let condition = nbPersonnes >= 13 ? 3 : 1;
                if (tmp > condition && tmp != lastTmp){
                    arrayNbTablesKeyPersonnePerTableValue[nbPremier] = tmp;
                    lastTmp = tmp;
                }
            }
        })
        return arrayNbTablesKeyPersonnePerTableValue;
    }
    function calcTempsParTours(nbPersonnes = 160, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayNbTablesKeyPersonnePerTableValue = calcNbPersonneParTable(nbPersonnes);
        let arrayTablesPersonnesDuree = {}
        let index = 0;
        let limited = true;
        for (const [key, value] of Object.entries(arrayNbTablesKeyPersonnePerTableValue)){
            if (parseInt(key) <= maxTablestmp && value <= maxPersParTable ){
                arrayTablesPersonnesDuree[index] = {
                    nbTables: parseInt(key),
                    nbPersonnesParTable: value,
                    dureeParTour: value*tempsParPersonne
                }
                limited = false;
            }
            index++;
        }
        if (limited && !stateLimited){
            setTmpLimited(true)
        }else if (!limited && stateLimited){
            setTmpLimited(false)
        }
        return arrayTablesPersonnesDuree;
    }
    function calculTempsTotalParToursSiNbTours(nbPersonnes, nbTours, auto, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayTablesPersonnesDuree = calcTempsParTours(nbPersonnes, maxTablestmp, maxPersParTable, setTmpLimited);
        let arrayFinal = {};
        for (const [key, value] of Object.entries(arrayTablesPersonnesDuree)){
            let tmpNbTours = auto ? value.nbTables+1 : nbTours
            arrayFinal[key] = {
                ...value,
                nbTours: tmpNbTours,
                tempsTotal: (value.dureeParTour * tmpNbTours) + (tempsPourChangerPlace * (tmpNbTours-1)) + dureeEntracte
            }
        }
        return arrayFinal;
    }

    function nbToursEnFonctionDeLaDuree(nbPersonnes, duree, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayTablesPersonnesDuree = calcTempsParTours(nbPersonnes, maxTablestmp, maxPersParTable, setTmpLimited);
        let arrayFinal = {};
        for (const [key, value] of Object.entries(arrayTablesPersonnesDuree)){
            let tmpNbTours = ((Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace)))) > value.nbTables + 1) ? value.nbTables + 1 : (Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace))));
            if (((Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace)))) < value.nbTables + 1)){
                arrayFinal[key] = {
                    ...value,
                    nbTours: tmpNbTours,
                    tempsTotal: value.dureeParTour * Math.floor((duree+tempsPourChangerPlace) / (value.dureeParTour+tempsPourChangerPlace)) + tempsPourChangerPlace * (Math.floor((duree+tempsPourChangerPlace) / (value.dureeParTour+tempsPourChangerPlace))-1) + dureeEntracte
                }
            }else {
                arrayFinal[key] = {
                    ...value,
                    nbTours: tmpNbTours,
                    tempsTotal: value.dureeParTour * tmpNbTours + (tempsPourChangerPlace * (tmpNbTours-1)) + dureeEntracte
                }
            }
        }
        return arrayFinal;
    }


    return <div className='mw-800px m-auto'>
        <Row>
            <Col>
                <Form.Group>
                    <Form.Label className='required'>Nom du speed business</Form.Label>
                    <Form.Control defaultValue={nom}
                                  onClick={(event) => {
                                      setNom(event.target.value)
                                  }}
                                  type="text"
                                  className='form-control-solid' width={10} placeholder='Nom du speed business'/>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="d-inline">
                    <Form.Label>Quelles sont vos préférences de réglages ? </Form.Label>
                    <div className="ms-2 btn-group" data-kt-buttons="true"
                         data-kt-buttons-target="[data-kt-button]">
                        <label
                            style={{
                                width: 150
                            }}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${(!privilegieDuree && !reglagesAuto) ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    setPrivilegieDuree(false)
                                    setReglagesAuto(false)
                                }}
                                className="btn-check" type="radio" name="method" value={true}/>
                            <span style={{fontSize: '10px'}}>Choisir le nombre de tour</span></label>
                        <label
                            style={{
                                width: 150
                            }}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${(privilegieDuree && !reglagesAuto) ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    setPrivilegieDuree(true)
                                    setReglagesAuto(false)
                                }}
                                className="btn-check" type="radio" name="method" value={false}/>
                            <span style={{fontSize: '10px'}}>Choisir la durée totale</span></label>
                        <label
                            style={{
                                width: 150
                            }}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${reglagesAuto ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    setReglagesAuto(true)
                                    setPrivilegieDuree(false)
                                }}
                                className="btn-check" type="radio" name="method" value={false}/>
                            <span style={{fontSize: '10px'}}>Privilégier les rencontres</span></label>
                    </div>
                </Form.Group>
            </Col>
        </Row>
        <Row className='mt-5'>
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <Form.Label className='required'>Nombre de personnes prévu</Form.Label>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={nbPersonne}
                                      onClick={(event) => {
                                          setNbPersonne(parseInt(event.target.value))
                                      }}
                                      onKeyUp={(event) => {
                                          setNbPersonne(parseInt(event.target.value))
                                      }} {...register('sujet', {required: true, valueAsNumber: true,})} type="number"
                                      className='form-control-solid' width={10} placeholder='Nombre de personnes'/>
                        <label className="input-group-text bg-white">personnes prévues</label>
                    </div>
                </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <Form.Label className='required'>Durée entracte</Form.Label>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={dureeEntracte}
                                      onClick={(event) => {
                                          setDureeEntracte(parseInt(event.target.value) ? parseInt(event.target.value) : 0 )
                                      }}
                                      onKeyUp={(event) => {
                                          setDureeEntracte(parseInt(event.target.value) ? parseInt(event.target.value) : 0 )
                                      }} type="number"
                                      className='form-control-solid' width={10} placeholder='Durée entracte'/>
                        <label className="input-group-text bg-white">minutes</label>
                    </div>
                </Form.Group>
            </Col>
            {privilegieDuree &&
                <Col lg={4} md={4} sm={6}>
                    <Form.Group>
                        <Form.Label className='required'>Durée totale en minutes</Form.Label>
                        <div className="input-group input-group mb-5">
                            <Form.Control defaultValue={duree}
                                          onClick={(event) => {
                                              setDuree(parseInt(event.target.value))
                                          }}
                                          onKeyUp={(event) => {
                                              setDuree(parseInt(event.target.value))
                                          }} {...register('duree', {required: true, valueAsNumber: true,})} type="number"
                                          className='form-control-solid' width={10} placeholder='Durée totale'/>
                            <label className="input-group-text bg-white">minutes au total</label>
                        </div>
                    </Form.Group>
                </Col>
            }
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <Form.Label className='required'>Nombre de tables maximum</Form.Label>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={tableMaximum}
                                      onClick={(event) => {
                                          setTableMaximum(parseInt(event.target.value))
                                      }}
                                      onKeyUp={(event) => {
                                          setTableMaximum(parseInt(event.target.value))
                                      }} type="number"
                                      className='form-control-solid' width={10} placeholder='Nombre de tables'/>
                        <label className="input-group-text bg-white">tables maximum</label>
                    </div>
                </Form.Group>
            </Col>
            <Col lg={5} md={5} sm={6}>
                <Form.Group>
                    <Form.Label className='required'>Nombre de personnes maximum par table</Form.Label>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={nbPersonneMaxParTable}
                                      onClick={(event) => {
                                          setNbPersonneMaxParTable(parseInt(event.target.value))
                                      }}
                                      onKeyUp={(event) => {
                                          setNbPersonneMaxParTable(parseInt(event.target.value))
                                      }} type="number"
                                      className='form-control-solid' width={10} placeholder='Nombre de personnes'/>
                        <label className="input-group-text bg-white">personnes par table maximum</label>
                    </div>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3" lg={6}>
                <Form.Label className='required mb-5'>Temps par personne (en seconde)</Form.Label>
                <RangeSlider range={tempsParPersonne + ""} setRange={setTempsParPersonne} rangeValues={{
                    1: {
                        text: "60",
                        valeur: 1,
                    },
                    2: {
                        text: "90",
                        valeur: 1.5,
                    },
                    3: {
                        text: "120",
                        valeur: 2,
                    },
                    4: {
                        text: "150",
                        valeur: 2.5,
                    },
                    5: {
                        text : "180",
                        valeur : 3,
                    },
                    6: {
                        text : "210",
                        valeur : 3.5,
                    },
                    7: {
                        text : "240",
                        valeur : 4,
                    },
                }} classContainer={"mt-5 position-relative"}/>
            </Col>
            <Col className="mt-3" lg={6}>
                <Form.Label className='required  mb-5'>Temps pour changer de table (en seconde)</Form.Label>
                <RangeSlider range={tempsPourChangerPlace + ""} step={0.5}  setRange={setTempsPourChangerDePlace} rangeValues={{
                    1: {
                        text : "60",
                        valeur : 1,
                    },
                    2: {
                        text : "90",
                        valeur : 1.5,
                    },
                    3: {
                        text : "120",
                        valeur : 2,
                    },
                    4: {
                        text : "150",
                        valeur : 2.5,
                    },
                    5: {
                        text : "180",
                        valeur : 3,
                    },
                    6: {
                        text : "210",
                        valeur : 3.5,
                    },
                    7: {
                        text : "240",
                        valeur : 4,
                    },
                }} classContainer={"mt-5 position-relative"}/>
            </Col>
            {(!privilegieDuree && !reglagesAuto) &&
                <Col className="mt-10" lg={12}>
                    <Form.Label className='required  mb-5'>Nombre de tours</Form.Label>
                    <RangeSlider range={nbTours + ""} setRange={setNbTours} rangeValues={{
                        1: {
                            text:"1",
                            valeur: 1
                        },
                        2: {
                            text:"2",
                            valeur: 2
                        },
                        3: {
                            text:"3",
                            valeur: 3
                        },
                        4: {
                            text:"4",
                            valeur: 4
                        },
                        5: {
                            text:"5",
                            valeur: 5
                        },
                        6: {
                            text:"6",
                            valeur: 6
                        },
                        7: {
                            text:"7",
                            valeur: 7
                        },
                        8: {
                            text:"8",
                            valeur: 8
                        },
                        9: {
                            text:"9",
                            valeur: 9
                        },
                        10: {
                            text:"10",
                            valeur: 10
                        },
                        11: {
                            text:"11",
                            valeur: 11
                        },
                        12: {
                            text:"12",
                            valeur: 12
                        },
                    }} classContainer={"mt-5 position-relative"}/>
                </Col>
            }

        </Row>

        <table className="table table-row-bordered mt-8">
            <thead style={{
                backgroundColor: "lightgray"
            }}>
            <tr style={{
                fontWeight: "bold"
            }} className="text-center">
                <th scope="col">Nombre table</th>
                <th scope="col">Nombre personnes par table</th>
                <th scope="col">Temps par tours</th>
                <th scope="col">Nb tours</th>
                <th scope="col">Temps total</th>
                <th scope="col">Pourcentage de rencontre</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {result && Object.values(result).map(row => {
                if (row.nbTours){
                    return <tr style={{
                        backgroundColor: (row == selectedRow ? "var(--primary-color)" : "white"),
                        color: (row == selectedRow ? "white" : "black"),
                        cursor: (row == selectedRow ? "pointer" : "default")
                    }}
                               onClick={() => {
                                   setSelectedRow(row)
                               }}
                               onMouseLeave={(event) => {
                                   if (selectedRow !== row) {
                                       event.currentTarget.style.backgroundColor = "white"
                                       event.currentTarget.style.color = "black"
                                       event.currentTarget.style.cursor = "default"
                                   }
                               }}
                               onMouseOver={(event) => {
                                   if (selectedRow !== row) {
                                       event.currentTarget.style.backgroundColor = "var(--primary-color)"
                                       event.currentTarget.style.color = "white"
                                       event.currentTarget.style.cursor = "pointer"
                                   }
                               }} className="text-center">
                        <th className="" scope="row">{row.nbTables}</th>
                        <td>{row.nbPersonnesParTable}</td>
                        <td>{Math.trunc(row.dureeParTour) + "m" + (row.dureeParTour % 1) * 60 + "s"}</td>
                        <td>{row.nbTours}</td>
                        <td>{Math.floor(row.tempsTotal / 60) + "h" + Math.trunc(row.tempsTotal % 60 > 9 ? row.tempsTotal % 60 : "0" + row.tempsTotal % 60) + "m" + (((row.tempsTotal % 60) % 1) * 60) + "s"}</td>
                        <td>{Math.trunc((row.nbTours/(row.nbTables+1))*100)+"%"}</td>
                        {selectedRow == row && <td
                            onClick={(event) => {
                                event.stopPropagation()
                                setSelectedRow(null);
                            }}><i style={{
                            color: "red"
                        }} className="fa fa-times me-3"></i></td>}
                        {selectedRow !== row && <td></td>}
                    </tr>
                }
            })}
            {stateLimited &&
                <tr>
                    <th className="" colSpan={6} scope="row"><Alert variant="danger">Le nombre de table maximum ou le nombre de personnes maximum par table est insuffisant.</Alert></th>
                </tr>
            }
            </tbody>
        </table>
        {selectedRow &&
            <Button variant="primary">
                suivant
            </Button>
        }

    </div>
        ;
});

export default ReglagesStep;