import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import illustration from "@assets/illustrations/16.png";
import { useSnapshot } from "valtio";
import toast from "react-hot-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import auth from "@services/auth";
import {useQueryClient} from "react-query";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";
import smsProxy from "../../../../../proxies/sms";
import useSendSmsAsTest from "../../../../../requests/communication/sms/useSendSmsAsTest";
import useSendForValidation from "../../../../../requests/communication/sms/useSendForValidation";
import {count} from "sms-length";
import useGetPricesSmsFormCoutries from "../../../../../requests/communication/sms/useGetPricesSmsFormCoutries";
import BlockUI from "../../../../../components/BlockUI/BlockUI";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";

function FinalisationStep() {
    const smsSnapshot = useSnapshot(smsProxy);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const sendAsTest = useSendSmsAsTest({
        id: smsSnapshot.sms?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            snapAuth.websocket.send("sms")

            toast.success('L\'envoi du sms a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        },
    });//todo fix error
    const getPriceSmsForTest = useGetPricesSmsFormCoutries((auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "06") || (auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "07") ? auth.utilisateur?.indicatifTel1 : ((auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "06") || (auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "07") ? auth.utilisateur?.indicatifTel2 : ""))
    const sendForValidation = useSendForValidation({
        id: smsSnapshot.sms?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            snapAuth.websocket.send("sms")

            toast.success('L\'envoi du sms a été envoyé pour validation.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        },
    });

    return <BlockUI loading={getPriceSmsForTest.isLoading}>
        <div className='w-100 mw-800px m-auto'>
            <div className='pt-15 pb-12 text-center'>
                <h1 className='fw-bolder text-dark'>Sms créé !</h1>
                <div className='text-muted fw-bold fs-4'>
                    Il ne reste plus qu'à l'envoyer
                </div>
            </div>
            <div className='d-flex flex-center pb-3'>
                <OverlayTrigger
                    key='smsTestInfo'
                    placement='right'
                    overlay={<Tooltip id='smsTestInfo'>
                        au numéro <span className='text-info'>{(auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "06") || (auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "07") ? auth.utilisateur?.tel1 : ((auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "06") || (auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "07") ? auth.utilisateur?.tel2 : "Veuillez renseigner un numéro de téléphone portable dans les informations de l'utilisateur.")}</span>
                    </Tooltip>}
                >
                    <Button className={"me-2"} onClick={() => {
                        let message = smsSnapshot.sms.message;
                        message = message.replace("{prenom}", snapAuth.utilisateur.prenom)
                        message = message.replace("{nom}", snapAuth.utilisateur.nom)
                        message = message.replace("{civilite}", snapAuth.utilisateur.civilite)
                        let objectTmp = count(message);
                        let nbMessage = objectTmp.messages;
                        let testTitle = (auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "06") || (auth.utilisateur?.tel1 && auth.utilisateur?.tel1.slice(0,2) == "07") ? auth.utilisateur?.indicatifTel1 : ((auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "06") || (auth.utilisateur?.tel2 && auth.utilisateur?.tel2.slice(0,2) == "07") ? auth.utilisateur?.indicatifTel2 : "");
                        let price = testTitle ? nbMessage*getPriceSmsForTest.data.data[0].prix : 0;
                        BtSwal.fire({
                            title: "Le prix de l'envoi en test est de "+price+ "€ \nConfirmez vous l'envoi ?",
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Non`,
                            icon: 'info',
                        }).then((res) => {
                            if (res.isConfirmed){
                                sendAsTest.mutate()
                            }
                        })

                    }} variant='danger' style={{height: "fit-content"}}>
                        M'envoyer le sms en test
                        <FontAwesomeIcon icon={faExclamationCircle} className='ms-2' />
                    </Button>

                </OverlayTrigger>
                <Button onClick={sendForValidation.mutate}>Envoyer pour validation</Button>
            </div>
            <div className={`d-flex flex-center pb-15 ${!smsSnapshot.sms?.isValidePourEnvoi ? 'd-none' : ''}`}>
                <Button
                    variant={"secondary"}
                    onClick={() => smsProxy.showSendSms(smsSnapshot.sms)}
                >Envoyer</Button>
            </div>
            <div className='text-center'>
                <img src={illustration} alt='' className='mww-100 mh-350px' />
            </div>
        </div>

    </BlockUI>
}

export default FinalisationStep;