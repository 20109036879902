import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

/**
 *
 * @param id id du post
 * @param libelle libelle de la catégorie du post pour mettre a jour seulement la bonne catégorie
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
export default function useDepublierSujet(id, libelle) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(({idEtat}) => {
        return api.post('sujetForum/'+id+'/depublier', {
            json: {
                etat: idEtat
            },
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries("sujetForumByCategories"+libelle);
            queryClient.invalidateQueries("sujetForum");
            queryClient.invalidateQueries("categorieSujet");
            snapAuth.websocket.send('sujetForumByCategories'+libelle)
            snapAuth.websocket.send("sujetForum")
            snapAuth.websocket.send("categorieSujet")

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}