import {Modal} from "react-bootstrap";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import annuaireProxy from "../../../../proxies/annuaire";


function InfoPersonneForSuiviModal() {
    const invitationsSnapshot = useSnapshot(invitationsProxy);

    return <Modal
        show={invitationsProxy.infoPersonneModal.show }
        onHide={() => invitationsProxy.infoPersonneModal.show = false}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Body className="d-flex justify-content-center">
            <div className="card card-body pt-5" style={{width: "fit-content", display : "relative"}}>
                <div className="d-flex align-items-center">
                    <span className='cursor-pointer svg-icon svg-icon-1 me-2' onClick={() => {
                        invitationsProxy.infoPersonneModal.show = false;
                        annuaireProxy.showPersonne(invitationsSnapshot.destinataire?.personne, false, "personne", true)
                    }} style={{position : "absolute", top : 10, right : 10}}><Art005 /></span>
                    <div className="symbol symbol-circle symbol-100px me-5">
                        <img src={invitationsSnapshot.destinataire?.personne?.upload?.urlOriginale} alt="image"/>
                    </div>
                    <div className="d-flex flex-column align-items-start">
                        <h3 className="mb-0">{invitationsSnapshot.destinataire?.personne?.prenom} {invitationsSnapshot.destinataire?.personne?.nom}</h3>
                        <div className="d-flex align-items-center gap-2">
                            <span className="svg-icon svg-icon-2">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                         height="24" viewBox="0 0 24 24" fill="none">
																		<path opacity="0.3"
                                                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                              fill="currentColor"></path>
																		<path
                                                                            d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                            fill="currentColor"></path>
																	</svg>
																</span>
                            <a href={"mailto:"+invitationsSnapshot.destinataire?.personne?.email} className="text-muted text-hover-primary">{invitationsSnapshot.destinataire?.personne?.email}</a>
                        </div>
                        <div className="d-flex align-items-center gap-2">

                            {invitationsSnapshot.destinataire?.personne?.tel1 && <div className="">
                                <span className="svg-icon svg-icon-2">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                         height="24" viewBox="0 0 24 24" fill="none">
																		<path
                                                                            d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                                                            fill="currentColor"></path>
																		<path opacity="0.3" d="M19 4H5V20H19V4Z"
                                                                              fill="currentColor"></path>
																	</svg>
																</span>
                                <a href={"tel:"+invitationsSnapshot.destinataire?.personne?.tel1} className="text-muted text-hover-primary">{invitationsSnapshot.destinataire?.personne?.tel1}</a>
                            </div> }
                            {invitationsSnapshot.destinataire?.personne?.tel2 && <div className="">
                                <span className="svg-icon svg-icon-2">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                         height="24" viewBox="0 0 24 24" fill="none">
																		<path
                                                                            d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                                                            fill="currentColor"></path>
																		<path opacity="0.3" d="M19 4H5V20H19V4Z"
                                                                              fill="currentColor"></path>
																	</svg>
																</span>
                                <a href={"tel:"+invitationsSnapshot.destinataire?.personne?.tel2} className="text-muted text-hover-primary">{invitationsSnapshot.destinataire?.personne?.tel2}</a>
                            </div> }
                        </div>
                        <div className="d-flex align-items-center mt-5">

                            {invitationsSnapshot.destinataire?.tabEntreprises && <div className="">
                                {invitationsSnapshot.destinataire?.tabEntreprises && Object.values(invitationsSnapshot.destinataire?.tabEntreprises).map((entreprise) => {
                                    return (<><span className="svg-icon svg-icon-2">
                                        <img src="/icon/building.svg" width={15} alt="entreprise icon"/>
			    													</span>
                                        <span className="text-muted"> {entreprise.nom}</span></>)
                                })}
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
}

export default InfoPersonneForSuiviModal;