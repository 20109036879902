import {Button} from "react-bootstrap";

function ConfirmViewInscriptionLibre({data, setShowRecap, isPaye = false}) {

    let montantAPayer = data?.correspondantInvitation?.tabPaiements[1]?.actif ? 0 : data?.correspondantInvitation?.optionParticipation?.montant;

    let mapIntives = [];

    if (data?.correspondantInvitation?.tabAccompagnants){
        Object.values(data?.correspondantInvitation?.tabAccompagnants).filter(item => !item.paieSaPart && !item.tabPaiements[1].actif).forEach(value => {
            if (!value.paieSaPart){
                montantAPayer += value?.optionParticipation?.montant
            }
        })
        mapIntives = Object.values(data?.correspondantInvitation?.tabAccompagnants).filter(item => !item.paieSaPart && !item.tabPaiements[1].actif).map((value, index) =>
            <tr>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="ms-5">
                            <div className="fs-7 text-muted">Invitation {data?.invitation?.dateEvenementFormatee} {data?.invitation?.type?.motDeLiaison}{data?.invitation?.type?.libelle} pour <span className="text-info fw-bolder">{value?.destinataire?.personne?.prenom} {value?.destinataire?.personne?.nom}</span></div>
                        </div>
                    </div>
                </td>
                <td className="text-end">{value?.optionParticipation?.montant} €</td>
            </tr>
        );

    }

    return <div className="text-center">
        {(data?.invitation?.nbParticipantsMax != 0 && !data?.correspondantInvitation?.tabPaiements[1].actif) && <div style={{width: "fit-content"}} className="alert alert-info m-auto mb-2">Votre place est réservé
            jusqu'à {data?.correspondantInvitation?.dateExpirationReservationPlace?.substring(11,)}</div>}
        <div className="card card-flush py-4 flex-row-fluid overflow-hidden mb-3">
            <div className="card-header">
                <div className="card-title">
                    <h2>Invitation #{data?.invitation?.id}</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                        <thead>
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-175px">Invités</th>
                            <th className="min-w-100px text-end">Total</th>
                        </tr>
                        </thead>
                        <tbody className="fw-bold text-gray-600">
                        {!data?.correspondantInvitation?.tabPaiements[1]?.actif  &&
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="ms-5">
                                            <div className="fs-7 text-muted">Invitation {data?.invitation?.dateEvenementFormatee} {data?.invitation?.type?.motDeLiaison}{data?.invitation?.type?.libelle} pour <span className="text-info fw-bolder">{data?.correspondantInvitation?.destinataire?.personne?.prenom} {data?.correspondantInvitation?.destinataire?.personne?.nom}</span></div>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-end">{data?.correspondantInvitation?.optionParticipation?.montant} €</td>
                            </tr>}

                        {mapIntives}


                        <tr>
                            <td colSpan="1" className="text-end">Sous-total</td>
                            <td className="text-end">{montantAPayer} €</td>
                        </tr>
                        {data.tauxTva !== 0 &&
                            <tr>
                                <td colSpan="1" className="text-end">TVA ({data.tauxTva}% inclus)</td>
                                <td className="text-end">{(montantAPayer - ( montantAPayer / (1 + (data.tauxTva/100) ) ) ).toFixed(2)}</td>
                            </tr>
                        }
                        <tr>
                            <td colSpan="1" className="fs-3 text-dark text-end">Montant total dû</td>
                            <td className="text-dark fs-3 fw-boldest text-end">{montantAPayer} €</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <Button
            disabled={isPaye}
            className="me-4 mb-7"
            variant="danger"
            onClick={() => {
                setShowRecap(false)
                // window.open(data?.correspondantInvitation?.urlAccepte, "_self")
            }}
        >
            Corriger
        </Button>
        <Button
            disabled={isPaye}
            className="mb-7"
            onClick={() => {
                window.open(data?.urlPaiement, "_self")
            }}
        >
            Payer {montantAPayer} €
        </Button>
    </div>;

}

export default ConfirmViewInscriptionLibre;
