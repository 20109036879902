import {Accordion, Button, Form, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Select2 from "../../../../components/Select2/Select2";
import React, {useEffect, useMemo, useState} from "react";
import $ from 'jquery'
import useChangeCorrespondant from "../../../../requests/communication/correspondant/useChangeCorrespondant";
import toast from "react-hot-toast";
import {useQueryClient} from "react-query";
import ConfirmView from "./ConfirmView";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import AccompagnantAddForm from "./AccompagnantForm/AccompagnantAddForm";
import TabGestionAccompagnants from "./TabGestionAccompagnants";
import AccompagnantEditForm from "./AccompagnantForm/AccompagnantEditForm";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import LoadingView from "../../../LoadingView/LoadingView";
import useRepondreInvitationByMail from "../../../../requests/communication/invitations/useRepondreInvitationByMail";
import {renderSwitchInscriptionInscriptionLibreEtPreview} from "../../../../views/communication/InvitationsView/functions";

function AccepteView({dataCorrespondant, dataInvitation, dataAccompagnateur, isRelance}) {

    let convertedDataCorrespondant = {...dataCorrespondant};
    let queryClient = useQueryClient();
    const [update, setUpdate] = useState(0);
    const [initRelance, setInitRelance] = useState(false);
    const nbParticipantsMax = dataInvitation.nbParticipantsMax;
    const nbParticipants = dataInvitation.nbParticipants;
    const nbPlacesRestantes = nbParticipantsMax - nbParticipants;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const repondreInvitationByMail = useRepondreInvitationByMail(ref);
    const changeCorrespondant = useChangeCorrespondant({
        onSuccess: (res) => {
            queryClient.invalidateQueries(['invitation']);
            // snapAuth.websocket.send("invitations")

            toast.success('Réponse modifié', {
                duration:5000
            });
            if (res?.message){
                BtSwal.fire(res.message, '', 'success')
            }
            if((res?.correspondantInvitation?.tabPaiements[2]?.actif || res?.correspondantInvitation?.tabPaiements[1]?.actif) && res?.urlPaiement){
                setDataRecap(res);
                setShowRecap(true);
            }
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
            setInvitationIsValidate(false);
            if (err.code == 410){
                err.data.correspondantInvitation.tabAccompagnants = Object.values(err.data.correspondantInvitation.tabAccompagnants);
                reset(err.data.correspondantInvitation)
            }
        },
    });
    const [showRecap, setShowRecap] = useState(false);
    const [dataRecap, setDataRecap] = useState(null);
    // const [tabAccompagnants, setTabAccompagnants] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentEditAccompagnant, setCurrentEditAccompagnant] = useState(null);
    const [currentEditIndex, setCurrentEditIndex] = useState(null);
    const [accompagnantAdd, setAccompagnantAdd] = useState(0);
    const [invitationIsValidate, setInvitationIsValidate] = useState(false);
    const [disableAddAccompagnant, setDisableAddAccompagnant] = useState(false);

    //Au premier render check s'il y a des données dans le localStorage pour cette ref. Si c'est le cas je redirige
    useEffect(() => {
        if (localStorage.getItem("dataInscriptionLibre"+dataInvitation?.lienInscriptionLibre.split("ref=")[1])){
            window.location.href = dataInvitation?.lienInscriptionLibre;
        }
    }, []);

    convertedDataCorrespondant.tabAccompagnants = Object.values(convertedDataCorrespondant.tabAccompagnants ?? {})
    //force une selection par défaut s'il y a des options de participation (non plus maintenant --> ça initilise a null)
    if ((convertedDataCorrespondant.optionParticipation == null || convertedDataCorrespondant.optionParticipation.id == null) && dataInvitation.tabOptionsParticipation && Object.keys(dataInvitation.tabOptionsParticipation).length > 0){
        convertedDataCorrespondant.optionParticipation = null
    }

    //si carte confirmé -> je met en attente de confirmation
    if (convertedDataCorrespondant.tabPaiements[1].actif){
        convertedDataCorrespondant.tabPaiements = [
            {
                id: null,
                libelle: "Sans paiement",
                actif: false
            },
            {
                id: 0,
                libelle: "Carte bancaire Confirmé",
                actif: false
            },
            {
                id: 1,
                libelle: "Carte bancaire en attente de confirmation",
                actif: true
            },
            {
                id: 2,
                libelle: "Sur place",
                actif: false
            },
            {
                id: 3,
                libelle: "Gratuit",
                actif: false
            }
        ]
    }
    //Initialise le tab paiement à sur place si sur place ou carte bancaire en attente de confirmation n'est pas actif
    else if (!convertedDataCorrespondant.tabPaiements[2]?.actif && !convertedDataCorrespondant.tabPaiements[3]?.actif){
        convertedDataCorrespondant.tabPaiements = [
            {
                id: null,
                libelle: "Sans paiement",
                actif: false
            },
            {
                id: 0,
                libelle: "Carte bancaire Confirmé",
                actif: false
            },
            {
                id: 1,
                libelle: "Carte bancaire en attente de confirmation",
                actif: true
            },
            {
                id: 2,
                libelle: "Sur place",
                actif: false
            },
            {
                id: 3,
                libelle: "Gratuit",
                actif: false
            }
        ]
    }


    const { register, handleSubmit, setValue, getValues, reset,
        formState: { errors } } = useForm({
        defaultValues: convertedDataCorrespondant,
    });

    useEffect(() => {
        if (nbParticipantsMax != 0){
            let nbPersonnesInvite = 0;
            let nbPersonneAPayer = 0;
            let messageError = "";
            let messageContactResp = " Pour plus d'informations vous pouvez contacter "+dataInvitation?.responsablePlaceLimite?.prenomNom+" par mail : " + (dataInvitation?.responsablePlaceLimite?.email ? dataInvitation?.responsablePlaceLimite?.email : "email non renseigné")+ " ou par téléphone au : "+(dataInvitation?.responsablePlaceLimite?.tel ? dataInvitation?.responsablePlaceLimite?.tel : "téléphone non renseigné");

            let nbPlacesRestantes = nbParticipantsMax - nbParticipants;
            if (accompagnantAdd !== 0){
                if (dataCorrespondant?.tabPaiements[0].actif){
                    nbPlacesRestantes = nbPlacesRestantes - 1;
                }
                getValues("tabAccompagnants").forEach((item) => {
                    nbPersonnesInvite = nbPersonnesInvite + 1;
                    if ((!item?.paieSaPart && (item?.tabPaiements[0].actif)) || (!item?.paieSaPart && !convertedDataCorrespondant?.dateExpirationReservationPlace && (item?.tabPaiements[2].actif || item?.tabPaiements[0].actif ))){
                        nbPersonneAPayer = nbPersonneAPayer + 1;
                    }
                })
                if (nbPersonneAPayer > nbPlacesRestantes){
                    messageError = "Attention, il ne reste que "+ nbPlacesRestantes + " places au total pour vos accompagnants. Certains de vos accompagnants ne pourrons pas s'inscrire."+messageContactResp
                }
                if (nbPersonnesInvite > nbPlacesRestantes) {
                    messageError = "Il reste "+nbPlacesRestantes +" places pour "+((nbPersonneAPayer+(nbPersonnesInvite-nbPersonneAPayer)) > 1 ? "vos "+(nbPersonneAPayer+(nbPersonnesInvite-nbPersonneAPayer))+ " accompagnants.": "votre accompagnant.") +messageContactResp
                }
                if (messageError !== ""){
                    showErrorAlert("Place disponibles insuffisantes", messageError);
                }
            }
            if (nbPlacesRestantes <= 0 || nbPersonnesInvite >= nbPlacesRestantes){
                setDisableAddAccompagnant(true);
            }else {
                setDisableAddAccompagnant(false)
            }
        }
    }, [accompagnantAdd])

    function onSave(data) {

        //On initialise correctement le tabPaiements
        if (dataInvitation.paiementEnLigneIsUtilise && !dataInvitation.paiementSurPlaceIsAutorise && !data.tabPaiements[1].actif){
            data.tabPaiements = [
                {
                    id: null,
                    libelle: "Sans paiement",
                    actif: false
                },
                {
                    id: 0,
                    libelle: "Carte bancaire Confirmé",
                    actif: false
                },
                {
                    id: 1,
                    libelle: "Carte bancaire en attente de confirmation",
                    actif: true
                },
                {
                    id: 2,
                    libelle: "Sur place",
                    actif: false
                },
                {
                    id: 3,
                    libelle: "Gratuit",
                    actif: false
                }
            ]
        }else if (!dataInvitation.paiementEnLigneIsUtilise){
            data.tabPaiements = [
                {
                    id: null,
                    libelle: "Sans paiement",
                    actif: false
                },
                {
                    id: 0,
                    libelle: "Carte bancaire Confirmé",
                    actif: false
                },
                {
                    id: 1,
                    libelle: "Carte bancaire en attente de confirmation",
                    actif: false
                },
                {
                    id: 2,
                    libelle: "Sur place",
                    actif: true
                },
                {
                    id: 3,
                    libelle: "Gratuit",
                    actif: false
                }
            ]
        }
        if (data?.optionParticipation?.montant == 0){
            data.tabPaiements = [
                {
                    id: null,
                    libelle: "Sans paiement",
                    actif: false
                },
                {
                    id: 0,
                    libelle: "Carte bancaire Confirmé",
                    actif: false
                },
                {
                    id: 1,
                    libelle: "Carte bancaire en attente de confirmation",
                    actif: false
                },
                {
                    id: 2,
                    libelle: "Sur place",
                    actif: false
                },
                {
                    id: 3,
                    libelle: "Gratuit",
                    actif: true
                }
            ]
        }

        if (nbParticipantsMax != 0 && checkOptionDeParticipation()){
            repondreInvitationByMail.refetch().then((dataRefecth) => {
                if (!checkAddAccompagnant(dataRefecth.data.invitation.nbParticipants, dataRefecth.data.invitation.nbParticipantsMax)){
                    changeCorrespondant.mutate(data);
                    setInvitationIsValidate(true);
                }
            })
        }else if (checkOptionDeParticipation()){
            changeCorrespondant.mutate(data);
            setInvitationIsValidate(true);
        }


    }

    const convertTabOptionsParticipation = dataInvitation?.tabOptionsParticipation ? Object.entries(dataInvitation?.tabOptionsParticipation).map(exp => exp[1]) : [];

    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [dataInvitation?.tabOptionsParticipation]);

    useEffect(() => {
        if (isRelance && !initRelance){
            setInitRelance(true)
            changeCorrespondant.mutate(dataCorrespondant)
        }
    }, [isRelance, dataCorrespondant])

    if (isRelance && changeCorrespondant.isLoading){
        return <LoadingView/>
    }


    if (!showRecap && dataCorrespondant?.paieSaPart){
        return <>
            {(!dataCorrespondant?.tabPaiements[0]?.actif && !dataCorrespondant?.tabPaiements[2]?.actif) && ((dataInvitation.paiementEnLigneIsUtilise && dataInvitation.paiementSurPlaceIsAutorise) || dataInvitation.champInformationComplementaireReponseIsAffiche || (dataInvitation.tabOptionsParticipation && Object.keys(dataInvitation.tabOptionsParticipation).length > 0) || dataInvitation.nbParticipantsMax != 0)
                ?
                <h1 className="mx-10 text-center alert-info-color">Vous pouvez apporter des informations supplémentaires
                    à votre réponse</h1>
                : <h1 className="mx-10 text-center alert-info-color">Nous sommes ravis de votre choix</h1>}
            {/*todo a mettre a différent endroits*/}
            {/*<div dangerouslySetInnerHTML={{ __html: dataInvitation?.informationComplementairePageReponse}} className='mt-5 mx-10 bg-white rounded p-4'>*/}
            {/*</div>*/}
            <div className='mt-5 mx-10 bg-white rounded'>
                {renderSwitchInscriptionInscriptionLibreEtPreview(dataInvitation.blocsPersonnalisesPageReponse, "champs2", "#403d38")}
            </div>
            <form>
                <div className=''>
                    {((dataInvitation.paiementEnLigneIsUtilise && dataInvitation.paiementSurPlaceIsAutorise) || dataInvitation.champInformationComplementaireReponseIsAffiche || (dataInvitation.tabOptionsParticipation && Object.keys(dataInvitation.tabOptionsParticipation).length > 0) || dataInvitation.nbParticipantsMax != 0) &&
                        <div className='mt-5 mx-10 bg-white rounded p-10 pb-4'>
                            {dataInvitation.nbParticipantsMax != 0 &&
                                <p style={{width: "fit-content"}} className="text-info">{nbPlacesRestantes} places
                                    restantes.</p>}
                            {dataInvitation.tabOptionsParticipation && Object.keys(dataInvitation.tabOptionsParticipation).length > 0 &&
                                <><Form.Group>
                                    <Form.Label>Option de participation</Form.Label>
                                    <Select2
                                        disabled={(convertedDataCorrespondant?.optionParticipation?.id != null && convertedDataCorrespondant?.tabOldPaiements[3].actif) || (convertedDataCorrespondant?.optionParticipation?.id != null && convertedDataCorrespondant?.tabPaiements[3].actif) || (convertedDataCorrespondant?.optionParticipation?.id != null && dataCorrespondant?.tabPaiements[1].actif)}
                                        allowClear={true}
                                        data={data}
                                        {...register('optionParticipation', {required: true})}
                                        selected={convertedDataCorrespondant?.optionParticipation?.id != null ? [convertedDataCorrespondant?.optionParticipation?.id.toString()] : null}
                                        onChange={(selected) => {
                                            selected = selected?.at(0);
                                            const res = convertTabOptionsParticipation.find(
                                                exp => exp.id.toString() === selected
                                            );
                                            setValue('optionParticipation', res);
                                        }}
                                        minimumResultsForSearch={3}
                                        placeholder='Selectionner une option de participation'

                                    />
                                    {errors.optionParticipation &&
                                        <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                </Form.Group>

                                    <div className='mt-4'>
                                        {renderSwitchInscriptionInscriptionLibreEtPreview(dataInvitation.blocsPersonnalisesPageReponse, "champs3", "#403d38")}
                                    </div>
                                </>
                            }
                            {dataInvitation.champInformationComplementaireReponseIsAffiche &&
                                <Form.Group className='mt-5'>
                                    <Form.Label>Information complémentaire sur votre réponse (facultatif) :</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={5}
                                        {...register('informationComplementaireReponse')} />
                                </Form.Group>}

                            {dataInvitation.paiementEnLigneIsUtilise && dataInvitation.paiementSurPlaceIsAutorise &&
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="btn-group" data-kt-buttons="true"
                                         data-kt-buttons-target="[data-kt-button]">
                                        <label
                                            id='surPlace'
                                            className={`${dataCorrespondant?.tabPaiements[1]?.actif ? `disabled` : ""} small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${convertedDataCorrespondant.tabPaiements[3].actif ? `active` : ``}`}
                                            data-kt-button="true">
                                            <input
                                                onClick={() => {
                                                    setValue('tabPaiements', [
                                                        {
                                                            id: null,
                                                            libelle: "Sans paiement",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 0,
                                                            libelle: "Carte bancaire Confirmé",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 1,
                                                            libelle: "Carte bancaire en attente de confirmation",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 2,
                                                            libelle: "Sur place",
                                                            actif: true
                                                        },
                                                        {
                                                            id: 3,
                                                            libelle: "Gratuit",
                                                            actif: false
                                                        }
                                                    ])
                                                    $("#surPlace").addClass('active')
                                                    $("#cb").removeClass('active')
                                                }
                                                }
                                                className="btn-check" type="radio" value={1}/>
                                            <span>{"Régler sur place"}</span></label>
                                        <label
                                            id="cb"
                                            className={`${dataCorrespondant?.tabPaiements[1]?.actif ? `disabled` : ""} small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${convertedDataCorrespondant.tabPaiements[2].actif ? `active` : ``}`}
                                            data-kt-button="true">
                                            <input
                                                onClick={() => {
                                                    setValue('tabPaiements', [
                                                        {
                                                            id: null,
                                                            libelle: "Sans paiement",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 0,
                                                            libelle: "Carte bancaire Confirmé",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 1,
                                                            libelle: "Carte bancaire en attente de confirmation",
                                                            actif: true
                                                        },
                                                        {
                                                            id: 2,
                                                            libelle: "Sur place",
                                                            actif: false
                                                        },
                                                        {
                                                            id: 3,
                                                            libelle: "Gratuit",
                                                            actif: false
                                                        }
                                                    ])
                                                    $("#surPlace").removeClass('active')
                                                    $("#cb").addClass('active')
                                                }
                                                }
                                                className="btn-check" type="radio" value={2}/>
                                            <span>{"Régler maintenant par carte bancaire"}</span></label>
                                    </div>
                                </div>
                            }
                        </div>}
                    {(dataCorrespondant.tabAccompagnants || getValues("tabAccompagnants").length > 0 || dataInvitation.listeCombienEtesVousAVenirIsAffiche) &&
                        <div className='mt-5 mx-10 bg-white rounded p-10'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {dataInvitation.listeCombienEtesVousAVenirIsAffiche &&
                                            <div
                                                className='mt-2 d-flex flex-wrap w-100 justify-content-between align-items-center'>
                                                <h2>Gestion des accompagnants <span
                                                    className="badge badge-circle badge-primary">{convertedDataCorrespondant.tabAccompagnants.length + accompagnantAdd}</span>
                                                </h2>
                                                <Button
                                                    disabled={disableAddAccompagnant}
                                                    variant='secondary'
                                                    className='px-20 me-3'
                                                    onClick={() => {
                                                        setShowAddModal(true)
                                                    }
                                                    }
                                                >
                                                    Ajouter un accompagnant</Button>
                                            </div>}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="table-responsive mt-3">
                                            <table className="table gy-7 gs-7">
                                                <tbody>
                                                <TabGestionAccompagnants
                                                    tabAccompagnants={getValues("tabAccompagnants")}
                                                    getValues={getValues}
                                                    setValue={setValue}
                                                    convertTabOptionsParticipation={convertTabOptionsParticipation}
                                                    data={data}
                                                    update={update}
                                                    setUpdate={setUpdate}
                                                    setCurrentEditAccompagnant={setCurrentEditAccompagnant}
                                                    setCurrentEditIndex={setCurrentEditIndex}
                                                    setShowEditModal={setShowEditModal}
                                                    accompagnantAdd={accompagnantAdd}
                                                    setAccompagnantAdd={setAccompagnantAdd}
                                                />
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>}
                    <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
                        {!invitationIsValidate ? <Button
                                variant='primary'
                                className='px-20'
                                onClick={handleSubmit(onSave)}
                            >{(dataCorrespondant?.tabDatesReponse) && ((dataInvitation.paiementEnLigneIsUtilise && dataInvitation.paiementSurPlaceIsAutorise) || dataInvitation.champInformationComplementaireReponseIsAffiche || (dataInvitation.tabOptionsParticipation && Object.keys(dataInvitation.tabOptionsParticipation).length > 0) || dataInvitation.nbParticipantsMax != 0) ?
                                <span>Modifier les informations {accompagnantAdd > 0 && "et ajouter les accompagnants"}</span> :
                                <span>Valider l'inscription {accompagnantAdd > 0 && "et ajouter les accompagnants"}</span>}</Button>
                            :
                            <p className="mx-10 alert alert-success text-center">Votre choix a bien été pris en
                                compte.</p>
                        }
                    </div>
                    <div className='mt-5 mx-10 bg-white rounded'>
                        {renderSwitchInscriptionInscriptionLibreEtPreview(dataInvitation.blocsPersonnalisesPageReponse, "champs4", "#403d38")}
                    </div>
                </div>
            </form>

            <Modal
                show={showAddModal}
                onHide={() => {
                    setShowAddModal(false)
                }}
                size='md'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <div className='w-100 stepper stepper-links d-flex flex-column between'>
                        <div className='stepper-nav'>
                            <h3 className='stepper-title'>Ajout d'un accompagnant</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <AccompagnantAddForm
                        accompagnantAdd={accompagnantAdd}
                        setAccompagnantAdd={setAccompagnantAdd}
                        setShowAddModal={setShowAddModal}
                        setValueInitial={setValue}
                        getValues={getValues}
                        data={data}
                        convertTabOptionsParticipation={convertTabOptionsParticipation}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showEditModal}
                onHide={() => {
                    setShowEditModal(false)
                }}
                size='md'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <div className='w-100 stepper stepper-links d-flex flex-column between'>
                        <div className='stepper-nav'>
                            <h3 className='stepper-title'>Modification d'un accompagnant</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <AccompagnantEditForm
                        setShowEditModal={setShowEditModal}
                        setValueInitial={setValue}
                        index={currentEditIndex}
                        accompagnant={currentEditAccompagnant}
                        getValues={getValues}
                    />
                </Modal.Body>
            </Modal>
        </>;
    } else if (dataCorrespondant?.paieSaPart) {
        return <ConfirmView
            data={dataRecap}
            setShowRecap={setShowRecap}
        />
    }else {
        return <p style={{width: "fit-content"}} className="m-auto text-center alert alert-info">Votre participation est déjà prise en charge par {dataAccompagnateur?.prenom + " " + dataAccompagnateur?.nom},
        <br/>
            {( dataAccompagnateur?.email || dataAccompagnateur?.tel1 ) ?
                "vous pouvez la contacter, " + (dataAccompagnateur?.email ? "par mail au " + dataAccompagnateur?.email + "." : "par téléphone au " + dataAccompagnateur?.tel1+".")
                : ""
            }
        </p>
    }
    function checkAddAccompagnant(nbParticipants, nbParticipantsMax){
        let nbPersonnesInvite = 0;
        let nbPersonneAPayer = 0;
        let messageError = "";
        let nbPlacesRestantes = nbParticipantsMax - nbParticipants;
        let messageContactResp = " Pour plus d'informations vous pouvez contacter "+dataInvitation?.responsablePlaceLimite?.prenomNom+" par mail : " + (dataInvitation?.responsablePlaceLimite?.email ? dataInvitation?.responsablePlaceLimite?.email : "email non renseigné")+ " ou par téléphone au : "+(dataInvitation?.responsablePlaceLimite?.tel ? dataInvitation?.responsablePlaceLimite?.tel : "téléphone non renseigné");

        if (dataCorrespondant?.tabPaiements[0].actif){
            nbPlacesRestantes = nbPlacesRestantes - 1;
        }
        getValues("tabAccompagnants").forEach((item) => {
            if (!item.id){
                nbPersonnesInvite = nbPersonnesInvite + 1;
                if ((!item?.paieSaPart && (item?.tabPaiements[0].actif)) || (!item?.paieSaPart && !convertedDataCorrespondant?.dateExpirationReservationPlace && (item?.tabPaiements[2].actif || item?.tabPaiements[0].actif ))){
                    nbPersonneAPayer = nbPersonneAPayer + 1;
                }
            }else if ((!item?.paieSaPart && (item?.tabPaiements[0].actif)) || (!item?.paieSaPart && !convertedDataCorrespondant?.dateExpirationReservationPlace && (item?.tabPaiements[2].actif || item?.tabPaiements[0].actif ))){
                nbPersonneAPayer = nbPersonneAPayer + 1;
            }
        })
        if (nbPersonneAPayer+(nbPersonnesInvite-nbPersonneAPayer) == 0){
            return false
        }
        if (nbPersonneAPayer > nbPlacesRestantes){
            messageError = "Attention, il ne reste que "+ nbPlacesRestantes + " places au total pour vos accompagnants. Certains de vos accompagnants ne pourrons pas s'inscrire."+messageContactResp
        }
        if (nbPersonnesInvite > nbPlacesRestantes) {
            messageError = "Il reste "+nbPlacesRestantes +" places pour "+((nbPersonneAPayer+(nbPersonnesInvite-nbPersonneAPayer)) > 1 ? "vos "+(nbPersonneAPayer+(nbPersonnesInvite-nbPersonneAPayer))+ " accompagnants.": "votre accompagnant.") +messageContactResp
        }
        if (messageError !== ""){
            showErrorAlert("Place disponibles insuffisantes", messageError);
        }
        if (nbPersonnesInvite > nbPlacesRestantes){
            setDisableAddAccompagnant(true);
            return true
        }else {
            setDisableAddAccompagnant(false)
            return false
        }
    }

    function checkOptionDeParticipation(){
        let result = true;
        getValues("tabAccompagnants").forEach((item) => {
            if (!item.paieSaPart && !item.optionParticipation && convertTabOptionsParticipation.length > 0){
                showErrorAlert("Erreur gestion des accompagnants", "Merci de sélectionner une option de participation pour les accompagnants dont vous prenez en charge la participation.");
                result = false;
            }
        })
        return result;
    }



}

export default AccepteView;
