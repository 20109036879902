import {useEffect, useState} from "react";
import { useController } from "react-hook-form";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import {getLoadOptionsPersonne} from "../../../requests/selectionRequests/personne";

function PersonneSelection({ control, name, rules }) {
    const { field } = useController({ control, name, rules });

    const [selectedValue, setSelectedValue] = useState(field.value != null ? {id:field.value.id,title:field.value.prenom+" "+field.value.nom} : null);
    const handleChange = value => {
        setSelectedValue(value);
    }

    useEffect(() => {
        setSelectedValue(field.value != null ? {id:field.value.id,title:field.value.prenom+" "+field.value.nom} : null)
    }, [field.value])

    const loadOptions = getLoadOptionsPersonne();
    const debouncedLoadOptions = debounce(loadOptions, 500)


    return <AsyncSelect
        placeholder={"Rechercher une personne"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        minLength={0}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? '#4fc9da' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'rgba(79,201,218,0.3)' : "#4fc9da",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#FFF",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        cacheOptions
        defaultOptions={true}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            field.onChange(selected.id)
        }}
    />
}

export default PersonneSelection;