import {useMutation} from "react-query";
import toast from "react-hot-toast";
import api from "@services/api";

export default function useResendSmsForOneCorrespondant() {

    return useMutation(resend, {
        onSuccess: () => {
            toast.success('Le sms a été envoyé', {
                duration:5000
            });
        },
    });
}

function resend(id) {

    if (id != null)
        return api.post(`correspondantSms/${id}/envoyerSms`).json();

}