import {Button, Card, Dropdown, Modal} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faTimes, faWrench} from "@fortawesome/free-solid-svg-icons";
import MetaTable from "@components/MetaTable/MetaTable";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import { ReactComponent as Gen016 } from "@assets/icons/gen016.svg";
import { ReactComponent as Gen027 } from "@assets/icons/gen027.svg";
import EditModal from "./EditModal/EditModal";
import invitationsProxy from "@proxies/invitations";
import SendModal from "./SendModal/SendModal";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import useDeleteInvitation from "../../../requests/communication/invitations/useDeleteInvitation";
import useValidInvitation from "../../../requests/communication/invitations/useValidInvitation";
import useInvalidInvitation from "../../../requests/communication/invitations/useInvalidInvitation";
import { ReactComponent as Gen034 } from "@assets/icons/gen034.svg";
import { ReactComponent as Gen037 } from "@assets/icons/gen037.svg";
import { ReactComponent as Relance } from "@assets/icons/relance.svg";
import { ReactComponent as RelancePaiementIcon } from "@assets/icons/relancePaiment.svg";
import { ReactComponent as Rappel } from "@assets/icons/rappel.svg";
import RappelModal from "./RappelModal/RappelModal";
import RelanceModal from "./RelanceModal/RelanceModal";
import { ReactComponent as SuiviEmailBlack } from "@assets/icons/suiviEmailV2.svg";
import useSendForValidationDataTable from "../../../requests/communication/invitations/useSendForValidationDataTable";
import toast from "react-hot-toast";
import {useQueryClient} from "react-query";
import { ReactComponent as Reply } from "@assets/icons/reply.svg";
import useGetCorrespondants from "../../../requests/communication/useGetCorrespondants";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import blocsProxy from "../../../proxies/blocs";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import moment from "moment";
import {ReactComponent as MemoCicle} from "@assets/icons/memoCicle.svg";
import useGetHeaders from "../../../requests/communication/header/useGetHeaders";
import useGetFooters from "../../../requests/communication/footer/useGetFooters";
import useGetExpediteurs from "../../../requests/communication/expediteur/useGetExpediteurs";
import LoadingView from "../../LoadingView/LoadingView";
import HeaderSettings from "../../ParametresView/HeaderSettings.js/HeaderSettings";
import FooterSettings from "../../ParametresView/FooterSettings/FooterSettings";
import ExpediteurSettings from "../../ParametresView/ExpediteursSettings/ExpediteurSettings";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import useGetDestinataireResponseInvitation
    from "../../../requests/communication/invitations/useGetDestinataireResponseInvitation";
import DestinataireReponseInvitationSettings
    from "../../ParametresView/DestinataireReponseInvitationSettings/DestinataireReponseInvitationSettings";
import useGetRappelInvitation from "../../../requests/parametres/rappelInvitation/useGetRappelInvitation";
import useGetBlocsModalite from "../../../requests/communication/invitations/blocsInvitation/useGetBlocsModalite";
import useGetBlocsChoix from "../../../requests/communication/invitations/blocsInvitation/useGetBlocsChoix";
import RappelMailSettings from "../../ParametresView/RappelMailSettings/RappelMailSettings";
import BlocModaliteSettings from "../../ParametresView/BlocModaliteSettings/BlocModaliteSettings";
import BlocResponseSettings from "../../ParametresView/BlocResponseSettings/BlocResponseSettings";
import useGetEmailInscriptionInvite from "../../../requests/communication/mails/useGetEmailInscriptionInvite";
import EmailInscriptionInviteSettings
    from "../../ParametresView/EmailInscriptionInviteSettings/EmailInscriptionInviteSettings";
import useGetMailJustificatifPaiement
    from "../../../requests/parametres/mailJustificatifPaiement/useGetMailJustificatifPaiement";
import useGetMailRelancePaiement from "../../../requests/parametres/mailRelancePaiement/useGetMailRelancePaiement";
import MailJustificatifSettings from "../../ParametresView/MailJustificatifSettings/MailJustificatifSettings";
import MailRelancePaiementSettings from "../../ParametresView/MailRelancePaiementSettings/MailRelancePaiementSettings";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import DetailInvitationModal from "./DetailInvitationModal";
import RelancePaiementModal from "./RelancePaiementModal/RelancePaiementModal";
import InscriptionLibreModal from "./IncriptionLibreModal/InscriptionLibreModal";

function InvitationsView() {
    const deleteInvitation = useDeleteInvitation()
    const validInvitation = useValidInvitation();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [searchFilter] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const getCorrespondants = useGetCorrespondants({
        onSuccess: (res) => {
            invitationsProxy.tabIdsDestinataires = res.data.map(data => data.destinataire.personne.id);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const sendForValidation = useSendForValidationDataTable({
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('Le mail de validation a bien été envoyé.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const invalidInvitation = useInvalidInvitation();
    const headers = useGetHeaders();
    const footers = useGetFooters();
    const expediteurs = useGetExpediteurs();
    const destinatairesReponses = useGetDestinataireResponseInvitation();
    const emailRappelInvitation = useGetRappelInvitation();
    const emailJustificatif = useGetMailJustificatifPaiement();
    const emailRelancePaiement = useGetMailRelancePaiement();
    const blocsModalite = useGetBlocsModalite();
    const blocsReponse = useGetBlocsChoix();
    const mailInscriptionInvite = useGetEmailInscriptionInvite();
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon icon={faWrench} color={"black"} />
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment()}
                            className='menu-link px-3'
                            onClick={() => {
                                if (snapAuth.tabIdInvitationVerrou.indexOf((row.original.id)) !== -1) {
                                    BtSwal.fire("Attention", "L'invitation est verrouillé en édition par un autre utilisateur", "warning")
                                } else {
                                    blocsProxy.blocChoix = null;
                                    blocsProxy.blocModalite = null;
                                    invitationsProxy.showEditInvitation(row.original)
                                    if (invitationsProxy.invitation?.corpsEmailTemplate?.header) {
                                        invitationsProxy.invitation.corpsEmailTemplate.header = invitationsProxy.invitation.header?.upload?.urlOriginale
                                    }
                                    if (invitationsProxy.invitation?.corpsEmailTemplate?.footer) {
                                        invitationsProxy.invitation.corpsEmailTemplate.footer = invitationsProxy.invitation.signature?.corps
                                    }
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005/></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment() || !row.original?.header?.id || !row.original?.signature?.id}
                            className='menu-link px-3'
                            onClick={() => {
                                invitationsProxy.showInscriptionLibre(row?.original);
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-1'> <i style={{width: 25, fontSize: 20}} className="fa fa-link"></i> </span>Personnaliser la page d'inscription libre
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                let data = {
                                    id: row.original.id,
                                    baseRoute: "invitation"
                                }
                                let newInvitation = {
                                    ...row.original
                                };
                                //reset quelques champs de l'invitation
                                newInvitation.id = null;
                                newInvitation.tabPeriodes = null;
                                newInvitation.tabOptionsParticipation = null;
                                newInvitation.dateCreation = null;
                                newInvitation.dateEnvoi = null;
                                newInvitation.dateProgrammationEnvoi = null;
                                newInvitation.dateReponse = null;
                                newInvitation.dateRelance = null;
                                newInvitation.dateRappel = null;
                                newInvitation.champInformationComplementaireReponseIsAffiche = false;
                                newInvitation.listeCombienEtesVousAVenirIsAffiche = false;
                                newInvitation.paiementEnLigneIsUtilise = false;
                                newInvitation.paiementSurPlaceIsAutorise = false;
                                newInvitation.isValidePourEnvoi = false;
                                //end reset
                                blocsProxy.blocChoix = null;
                                blocsProxy.blocModalite = null;
                                invitationsProxy.showEditInvitation(newInvitation)
                                getCorrespondants.mutate(data)
                                if (invitationsProxy.invitation?.corpsEmailTemplate?.header) {
                                    invitationsProxy.invitation.corpsEmailTemplate.header = invitationsProxy.invitation.header?.upload?.urlOriginale
                                }
                                if (invitationsProxy.invitation?.corpsEmailTemplate?.footer) {
                                    invitationsProxy.invitation.corpsEmailTemplate.footer = invitationsProxy.invitation.signature?.corps
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Reply/></span>
                            Rédiger une invitation à partir de celle-ci
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment() || !row.original?.header?.id || !row.original?.signature?.id}
                            className='menu-link px-3'
                            onClick={() => invitationsProxy.showSendInvitation(row.original)}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen016/></span>
                            Envoyer
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment() || !row.original?.header?.id || !row.original?.signature?.id}
                            className='menu-link px-3'
                            onClick={() => invitationsProxy.showRappelInvitation(row.original)}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Rappel/></span>
                            Envoyer rappel
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment() || !row.original?.header?.id || !row.original?.signature?.id}
                            className='menu-link px-3'
                            onClick={() => invitationsProxy.showRelanceInvitation(row.original)}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Relance/></span>
                            Envoyer relance
                        </Dropdown.Item>
                    </div>
                    {/*<div className='menu-item px-3'>*/}
                    {/*    <Dropdown.Item*/}
                    {/*        hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment() || !row.original?.header?.id || !row.original?.signature?.id}*/}
                    {/*        className='menu-link px-3'*/}
                    {/*        onClick={() => invitationsProxy.showRelancePaiementInvitation(row.original)}*/}
                    {/*    >*/}
                    {/*        <span className='svg-icon svg-icon-1 me-2'><RelancePaiementIcon /></span>*/}
                    {/*        Envoyer relance paiement*/}
                    {/*    </Dropdown.Item>*/}
                    {/*</div>*/}
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => invitationsProxy.showSuiviInvitation(row.original)}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><SuiviEmailBlack/></span>Suivi de l'invitation
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        {row.original.isValidePourEnvoi
                            ? <Dropdown.Item className='menu-link px-3'
                                             hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment()}
                                             onClick={() => invalidInvitation.mutate(row.original.id)}> <span
                                className='svg-icon svg-icon-1 me-2'><Gen034/></span> Invalider l'invitation
                            </Dropdown.Item>
                            : <Dropdown.Item className='menu-link px-3'
                                             hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment()}
                                             onClick={() => validInvitation.mutate(row.original.id)}><span
                                className='svg-icon svg-icon-1 me-2'><Gen037/></span>Valider
                                l'invitation</Dropdown.Item>
                        }

                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => BtSwal.fire({
                                title: 'Êtes vous sur de vouloir supprimer l\'invitation ?',
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    deleteInvitation.mutate(row.original.id);
                                    BtSwal.fire('L\'invitation a été supprimé!', '', 'success')
                                } else if (result.isDenied) {
                                    BtSwal.fire('L\'invitation n\'a pas été supprimé', '', 'info')
                                }
                            })}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027/></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell: ({value, row}) => {
                if (snapAuth.tabIdInvitationVerrou.indexOf((row.original.id)) !== -1) {
                    return <span className="d-flex align-items-center">{value} <span
                        className="ms-4 badge badge-circle badge-secondary"></span></span>
                }
                return value;
            }
        },
        {
            Header: 'Sujet',
            accessor: 'sujet',
            minWidth: 300,
            noWrap: true
        },
        {
            Header: "Prêt à l'envoi",
            accessor: 'isValidePourEnvoi',
            minWidth: 180,
            maxWidth: 180,
            Cell: ({value, row}) => {
                if (value == null)
                    return '';

                return <>
                    <div
                        hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment()}
                        className="btn-group" data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]">
                        <label
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!value ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    invalidInvitation.mutate(row.original.id)
                                }}
                        className="btn-check" type="radio" name="method" value={value.id}/>
                    <span style={{fontSize: '10px'}}>A valider</span></label>
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                validInvitation.mutate(row.original.id)
                            }}
                            className="btn-check" type="radio" name="method" value={value.id}/>
                        <span style={{fontSize: '10px'}}>Validé</span></label>
                </div>
                    <span
                        hidden={moment(Object.values(row?.original?.tabPeriodes)[0]?.debut.split(" ")[0], "DD/MM/YYYY") < moment()}
                        onClick={() => sendForValidation.mutate(row.original.id)}
                        title='Envoyer pour validation' className='ms-2 cursor-pointer'
                    ><span className='svg-icon svg-icon-fluid me-2 w-30px d-inline-block'><MemoCicle /></span></span>
                </>
            },
        },
        {
            Header: "Date de l'événement",
            accessor: 'dateEvenementFormatee',
            minWidth: 300,
        },
        {
            Header: "Envoi programmé le",
            accessor: 'dateProgrammationEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            Header: "Date d'envoi",
            accessor: 'dateEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            Header: 'Date de création',
            accessor: 'dateCreation',
            minWidth: 165,
            maxWidth: 165,
        },
    ], [snapAuth.tabIdInvitationVerrou]);

    if (headers.isLoading || footers.isLoading || expediteurs.isLoading || destinatairesReponses.isLoading || emailRappelInvitation.isLoading || blocsReponse.isLoading || blocsModalite.isLoading || mailInscriptionInvite.isLoading || emailJustificatif.isLoading || emailRelancePaiement.isLoading){
        return <LoadingView/>
    }

    if (paramsIsOk() !== true){
        return  <Card style={{height: "80vh"}}><div className="table-responsive">
            <h1 className="text-center mt-3
            ">Paramètres obligatoire avant la rédaction des invitations</h1>
            <table className="m-auto table mt-4 table-row-dashed w-800px table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                    <th className="w-400px rounded-start"></th>
                    <th className="w-140px">Paramétré</th>
                </tr>
                </thead>
                <tbody className="border-bottom border-dashed">
                {paramsIsOk()}
                </tbody>
            </table>
            <Modal
                show={showModalSettings}
                onHide={() => setShowModalSettings(false)}
                size='xl'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Paramètres invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentComponentSettings}
                </Modal.Body>
            </Modal>
        </div></Card>
    }else {
        return <>
            <Card>
                <Card.Body>
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <div className='mb-5'>
                            <Button variant='secondary'
                                    onClick={() => {
                                        invitationsProxy.showEditInvitation(null);
                                        blocsProxy.blocChoix = null;
                                        blocsProxy.blocModalite = null;
                                    }}>
                                <FontAwesomeIcon icon={faPlus} className='me-2' />
                                Créer une invitation
                            </Button>
                        </div>
                    </div>
                    <div>
                        <MetaTable
                            className='mt-5'
                            height={500}
                            url='invitation'
                            keys={['invitations']}
                            columns={columns}
                            search={searchFilter}
                        />
                    </div>
                </Card.Body>
            </Card>
            <EditModal/>
            <SendModal />
            <RappelModal />
            <RelanceModal />
            <DetailInvitationModal/>
            <RelancePaiementModal/>
            <InscriptionLibreModal/>
        </>;

    }


    function paramsIsOk(){
        let result = [];
        let allIsOk = true;
        //check header
        if (headers.isSuccess && headers.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Entête de mail", <HeaderSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Entête de mail", <HeaderSettings />))
        }
        //check footer
        if (footers.isSuccess && footers.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Signature de mail", <FooterSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Signature de mail", <FooterSettings />))
        }
        // check expéditeur
        if (expediteurs.isSuccess && expediteurs.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Exépditeurs de mail", <ExpediteurSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Expéditeurs de mail", <ExpediteurSettings />))
        }
        // check destinataire réponses
        if (destinatairesReponses.isSuccess && destinatairesReponses.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Destinaires pour les réponses aux invitations", <DestinataireReponseInvitationSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Destinaires pour les réponses aux invitations", <DestinataireReponseInvitationSettings />))
        }

        // check email rappel
        if (emailRappelInvitation.isSuccess && emailRappelInvitation.data.corpsHtml == null){
            result.push(paramPasOkLine("Mail de rappel de l'invitation", <RappelMailSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail de rappel de l'invitation", <RappelMailSettings />))
        }

        // check email justificatifs
        if (emailJustificatif.isSuccess && emailJustificatif.data.corpsHtml == null){
            result.push(paramPasOkLine("Mail des justificatifs de paiement", <MailJustificatifSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail des justificatifs de paiement", <MailJustificatifSettings />))
        }

        // check email relance paiement
        if (emailRelancePaiement.isSuccess && emailRelancePaiement.data.corpsHtml == null){
            result.push(paramPasOkLine("Mail de relance de paiement", <MailRelancePaiementSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail de relance de paiement", <MailRelancePaiementSettings />))
        }

        // check email invitation invité
        if (mailInscriptionInvite.isSuccess && mailInscriptionInvite.data.corpsHtml == null){
            result.push(paramPasOkLine("Mail d'inscription d'un invité à une invitation", <EmailInscriptionInviteSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail d'inscription d'un invité à une invitation", <EmailInscriptionInviteSettings />))
        }

        // check bloc modalite
        if (blocsModalite.isSuccess && blocsModalite.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Blocs de modalité des invitations", <BlocModaliteSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Blocs de modalité des invitations", <BlocModaliteSettings />))
        }

        // check bloc choix
        if (blocsReponse.isSuccess && blocsReponse.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Blocs de réponse des invitations", <BlocResponseSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Blocs de réponse des invitations", <BlocResponseSettings />))
        }

        return allIsOk ? allIsOk : result;
    }

    function paramOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faCheck} color={"green"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                    setCurrentComponentSettings(settingsConponent)
                    setShowModalSettings(true)
                }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
            </td>
        </tr>
    }

    function paramPasOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faTimes} color={"red"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                    setCurrentComponentSettings(settingsConponent)
                    setShowModalSettings(true)
                }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>

            </td>
        </tr>
    }
}

export default InvitationsView;