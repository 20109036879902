import { ReactComponent as Logo } from "@assets/logo.svg";
import {Button, Card} from "react-bootstrap";
import moment from "moment";
import useGetVerifPaiement from "../../../requests/stripe/useGetVerifPaiement";
import LoadingView from "../../LoadingView/LoadingView";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import {useQueryClient} from "react-query";
import {useState} from "react";
import useChangeFacture from "../../../requests/communication/invitations/useChangeFacture";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import ConfirmInfosFacturationView
    from "../../../views/communication/InvitationsView/Response/PaiementView/ConfirmInfosFacturationView";

function AfficherRecuView() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const refPaiement = urlParams.get('referencePaiement') ? urlParams.get('referencePaiement').replace(/ /g, "+") : "";
    const sendMail = false;
    const verifPaiement = useGetVerifPaiement(ref, refPaiement, sendMail);
    const snapAuth = useSnapshot(auth);
    const queryClient = useQueryClient();
    const [showUrls, setShowUrls] = useState(true);
    const [urlFacture, setUrlFacture] = useState("#");
    const changeFacture = useChangeFacture({
        onSuccess: (res) => {
            queryClient.setQueryData('facture', res);
            queryClient.invalidateQueries('useGetVerifPaiement');

            // snapAuth.websocket.send("facture")

            toast.success("Information pour la facturation enregistré.");
            setShowUrls(true)
            setUrlFacture(res.url)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la génération de la facture", err.message);
        },
    });

    if (verifPaiement.isError || ref === ""){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Reçu</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <p>{verifPaiement.error?.message} <span className='svg-icon svg-icon-1 me-2'><img width={25} src="/icon/shield-xmark.svg" alt="shield-check"/></span></p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!verifPaiement.isSuccess){
        return <LoadingView/>
    }

    if (!showUrls) {
        return <ConfirmInfosFacturationView
            infosFacturation={verifPaiement?.data}
            changeFacture={changeFacture}
        />
    }else {
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Reçu et facture</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <Card className="p-7">
                            <p className="text-center">Facture payée</p>
                            <h2 className="text-center">{parseFloat(verifPaiement?.data?.montantPaiement/100).toFixed(2)}</h2>
                            <i className="fas fa-receipt fa-3x text-center"></i>
                            <div>
                                <div className="mt-5 flex-wrap d-flex m-auto justify-content-between mw-450px">
                                    <p>Numéro de facture</p><p>{verifPaiement?.data?.numFacture}</p>
                                </div>
                                <div className="mw-450px m-auto flex-wrap d-flex justify-content-between">
                                    <p>Date du paiement</p><p>{moment(verifPaiement?.data?.datePaiement*1000).format("DD/MM/YYYY")}</p>
                                </div>
                                <div className="mw-450px m-auto d-flex flex-wrap justify-content-between">
                                    <p>Moyen de paiement</p><p>{verifPaiement?.data?.typeCarteBancaire} **** {verifPaiement?.data?.numCarteBancaire}</p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => {
                                            setShowUrls(false)
                                        }}

                                        style={{backgroundColor : "#c2c2c2"}}
                                        className="me-2">
                                        {!verifPaiement?.data?.facture?.url ? "Télécharger la facture" : "Corriger la facture" }
                                    </Button>
                                    {verifPaiement?.data?.facture?.url && <Button
                                        onClick={() => {
                                            window.open(verifPaiement?.data?.facture?.url, "_blank")
                                        }}
                                        variant={"secondary"}
                                        className="me-2">
                                        Télécharger la facture
                                    </Button>}
                                    <Button
                                        onClick={() => {
                                            window.open(verifPaiement?.data?.urlRecu, "_blank")
                                        }}
                                        className="me-2">
                                        Télécharger le reçu
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;

    }


}

export default AfficherRecuView;