import { proxy } from "valtio";

const speedBusinessProxy = proxy({
    showEditSpeedBusinessModal: false,
    speedBusiness:null,
    step: 0,
    loading: false,
    showEditSpeedBusiness:(speedBusiness) => {
        speedBusinessProxy.showEditSpeedBusinessModal = true;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.step = 0;
    },
});

export default speedBusinessProxy;