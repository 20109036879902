import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useEffect, useRef, useState} from "react";
import { useSnapshot } from "valtio";
import BlockUI from '@components/BlockUI/BlockUI';
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import ReglageStep from "../../../views/SpeedBusinessView/ReglageStep/ReglageStep";

function EditModal() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    let queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const speedSnap = useSnapshot(speedBusinessProxy);

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) =>  <ReglageStep ref={ref}/>
        },
        // {
        //     name: 'Rédaction',
        //     renderComponent: (ref) => <RedactionStep ref={ref} />
        // },
        // {
        //     name: 'Destinataires',
        //     renderComponent: (ref) => <DestinatairesStep baseRoute='email' id={mailsSnapshot.mail?.id} />
        // },
        // {
        //     name: 'Finalisation',
        //     renderComponent: (ref) => <FinalisationStep />,
        // },
    ];

    async function hideValidation() {
        if (currentIndex === 3){
            // mailsProxy.editModal.show = false;
        }else {
            const result = await BtSwal.fire({
                title: 'Voulez-vous quitter ?',
                text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
                icon: 'question',
                showDenyButton: true,
                denyButtonText: 'Quitter',
                confirmButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-secondary',
                    denyButton: 'btn btn-danger',
                },
            });
            if (result.isDenied){
                speedBusinessProxy.showEditSpeedBusinessModal = false;
            }
        }
        // queryClient.invalidateQueries('emails');
    }

    async function goToStep(step) {
        setCurrentIndex(step);
        //todo check sur les destinataires checké mais pas rajouté voir editModal de l'email
        if (stepRef.current?.save != null) {
            speedBusinessProxy.loading = true;
            if (await stepRef.current.save())
                speedBusinessProxy.step = step;
            speedBusinessProxy.loading = false;
        }
        speedBusinessProxy.step = step;
    }

    return <Modal
        fullscreen
        show={speedSnap.showEditSpeedBusinessModal}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === speedBusinessProxy.step ? 'current' : ''} ${index < speedBusinessProxy.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={speedBusinessProxy.loading} className='overflow-scroll modal-body'>
            {steps[speedBusinessProxy.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {speedBusinessProxy.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => speedBusinessProxy.step--}
            >Précédent</Button>}
            {speedBusinessProxy.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(speedBusinessProxy.step + 1);
                }}
            >Suivant</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;