import { Navigate } from "react-router";
import LoginView from "@views/LoginView/LoginView";
import IndexView from "@views/IndexView/IndexView";
import AccueilView from '@views/AccueilView/AccueilView';
import MailsView from '@views/communication/MailsView/MailsView';
import InvitationsView from "@views/communication/InvitationsView/InvitationsView";
import ForumView from "@views/forum/ForumView/ForumView";
import AnnuaireView from "@views/AnnuaireView/AnnuaireView";
import Guarded from "@components/Guarded/Guarded";
import NotFoundView from "@views/NotFoundView/NotFoundView";
import ParametresView from "../views/ParametresView/ParametresView";
import ValidInvalidInvitationView from "../views/communication/InvitationsView/ValidInvalidInvitationView";
import ValidInvalidMailView from "../views/communication/MailsView/ValidInvalidMailView";
import AfficherInvitationView from "../views/communication/InvitationsView/AfficherInvitationView";
import SendResetPasswordView from "../views/ResetPasswordView/SendResetPasswordView";
import ResetPasswordView from "../views/ResetPasswordView/ResetPasswordView";
import SendIdentifiantView from "../views/ResetPasswordView/SendIdentifiantView";
import ResponseView from "../views/communication/InvitationsView/Response/ResponseView";
import AfficherMailByMail from "../views/communication/MailsView/AfficherMailByMail";
import AccesPartenaireView from "../views/communication/AccesPartenaireView/AccesPartenaireView";
import CGUView from "../views/CGUView/CGUView";
import SuccessPaiementView from "../views/communication/InvitationsView/Response/PaiementView/SuccessPaiementView";
import CancelPaiementView from "../views/communication/InvitationsView/Response/PaiementView/CancelPaiementView";
import TransfereView from "../views/communication/InvitationsView/Response/TransfereView/TransfereView";
import UserFetcher from "../components/UserFetcher/UserFetcher";
import AfficherRecuView from "../views/communication/InvitationsView/AfficherRecuView";
import ParametresForumView from "../views/ParametresForumView/ParametresForumView";
import SetVarCssAgence from "../components/SetVarCssAgence/SetVarCssAgence";
import IndexViewPublic from "../views/IndexView/IndexViewPublic";
import SuccessView from "../views/ResetPasswordView/SuccessView";
import TransfereMailView from "../views/communication/MailsView/TransfereView/TransfereMailView";
import SmsView from "../views/communication/SmsView/SmsView";
import ValidInvalidSmsView from "../views/communication/SmsView/ValidInvalidSmsView";
import GestionNotificationPublicView
    from "../views/communication/GestionNotificationPublicView/GestionNotificationPublicView";
import PublicationsMobileView from "../views/communication/PublicationsView/PublicationsMobileView";
import AfficherPublicationView from "../views/communication/PublicationsView/AfficherPublicationView";
import OauthResponse from "../views/ParametresView/FacebookSettings/OauthResponse";
import {default as OauthResponseLinkedin}  from "../views/ParametresView/LinkedinSettings/OauthResponse";
import PublicationReseauSocialView
    from "../views/communication/PublicationReseauSocialView/PublicationReseauSocialView";
import HomeView from "../views/Home/HomeView";
import HomeLoginView from "../views/Home/HomeLoginView";
import OauthRedirect from "../views/ParametresView/FacebookSettings/OauthRedirect";
import {default as OauthRedirectLinkedin} from "../views/ParametresView/LinkedinSettings/OauthRedirect";
import FacturationView from "../views/FacturationView/Facture/FacturationView";
import ReversionView from "../views/FacturationView/Reversion/ReversionView";
import RedirectAppMobile from "../views/redirectAppMobile";
import PresentationView from "../views/communication/InvitationsView/Response/IncrisptionLibre/PresentationView";
import SpeedBusinessView from "../views/SpeedBusinessView/SpeedBusinessView";

export const routes = [
    {
        path: '/login',
        element:
            <HomeLoginView />
    },
    {
        path: '/:agence',
        element: <SetVarCssAgence>
            <IndexViewPublic/>
        </SetVarCssAgence>,
        children: [
            {
                path: 'login',
                element: <LoginView/>,
            },
            {
                path: 'sendResetPassword',
                element: <SendResetPasswordView/>,
            },
            {
                path: 'sendIdentifiant',
                element: <SendIdentifiantView/>,
            },
            {
                path: 'success',
                element: <SuccessView/>,
            },
            {
                path: 'publicationMobile',
                element: <AfficherPublicationView/>,
            },
            {
                path: 'utilisateur/demandeReinitialisationPassword',
                element: <ResetPasswordView/>,
            },
            {
                path: 'invitation/valider',
                element: <ValidInvalidInvitationView/>,
            },
            {
                path: 'invitation/transferer',
                element: <TransfereView/>,
            },
            {
                path: 'email/transferer',
                element: <TransfereMailView/>,
            },
            {
                path: 'email/valider',
                element: <ValidInvalidMailView/>,
            },
            {
                path: 'email/afficher',
                element: <AfficherMailByMail/>,
            },
            {
                path: 'sms/valider',
                element: <ValidInvalidSmsView/>,
            },
            {
                path: 'invitation/afficher',
                element: <AfficherInvitationView/>
            },
            {
                path: 'invitation/repondre',
                element: <ResponseView/>
            },
            {
                path: 'invitation/inscriptionLibre',
                element: <PresentationView/>
            },
            {
                path: 'communication/gestionNotification',
                element: <GestionNotificationPublicView/>
            },
            {
                path: 'paiement/success',
                element: <SuccessPaiementView/>,
            },
            {
                path: 'paiement/justificatif',
                element: <AfficherRecuView/>,
            },
            {
                path: 'paiement/cancel',
                element: <CancelPaiementView/>,
            },
            {
                path: 'cgu',
                element: <CGUView />
            },
        ]
    },
    {
        path: '/compteFacebook/oauth',
        element:
            <OauthRedirect />
    },
    {
        path: '/compteLinkedin/oauth',
        element:
            <OauthRedirectLinkedin />
    },
    {
        path: '/:agence/redirectAppMobile',
        element:
            <RedirectAppMobile />
    },
    {
        path: '/:agence/compteFacebook/oauth',
        element:
            <UserFetcher><Guarded><OauthResponse /></Guarded></UserFetcher>
    },
    {
        path: '/:agence/compteLinkedin/oauth',
        element:
            <UserFetcher><Guarded><OauthResponseLinkedin /></Guarded></UserFetcher>
    },
    {
        path: '/:agence',
        element: <SetVarCssAgence><UserFetcher><Guarded>
            <IndexView />
        </Guarded></UserFetcher></SetVarCssAgence>,
        children: [
            { index: true, element: <Navigate to="accueil" replace={true} /> },
            {
                path: 'accueil',
                element: <AccueilView />,
            },
            {
                path: 'annuaire',
                element: <AnnuaireView />,
            },
            {
                path: 'communication/mails',
                element: <MailsView />,
            },
            {
                path: 'communication/publicationsReseauxSociaux',
                element: <PublicationReseauSocialView />,
            },
            {
                path: 'communication/invitations',
                element: <InvitationsView />,
            },
            {
                path: 'communication/acces',
                element: <AccesPartenaireView />,
            },
            {
                path: 'communication/publicationsMobile',
                element: <PublicationsMobileView />,
            },
            {
                path: 'communication/sms',
                element: <SmsView />,
            },
            {
                path: 'facturation/facture',
                element: <FacturationView />,
            },
            {
                path: 'facturation/reversion',
                element: <ReversionView />,
            },
            {
                path: 'forum',
                element: <ForumView />,
            },
            {
                path: 'speedBusiness',
                element: <SpeedBusinessView/>,
            },
            {
                path: 'parametres',
                element: <ParametresView />,
            },
            {
                path: 'parametres/stripe',
                element: <ParametresView stripe={true} />,
            },
            {
                path: 'parametres/facebook',
                element: <ParametresView facebook={true} />,
            },
            {
                path: 'parametres/linkedin',
                element: <ParametresView linkedin={true} />,
            },
            {
                path: 'parametres/forum',
                element: <ParametresForumView />,
            },
        ],
    },
    {
        path: '/',
        element:
            <HomeView />
    },
    { path: '*', element: <NotFoundView /> },
];