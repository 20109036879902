import {Button, Card, Dropdown, Modal} from "react-bootstrap";
import {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faWrench} from "@fortawesome/free-solid-svg-icons";
import MetaTable from "@components/MetaTable/MetaTable";
import moment from "moment";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import {ReactComponent as Gen027} from "@assets/icons/gen027.svg";
import {ReactComponent as Gen034} from "@assets/icons/gen034.svg";
import {ReactComponent as Gen037} from "@assets/icons/gen037.svg";
import {ReactComponent as MemoCicle} from "@assets/icons/memoCicle.svg";
import {ReactComponent as Pricing} from "@assets/icons/pricing.svg";
import {useQueryClient} from "react-query";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import EditModal from "./EditModal/EditModal";
import smsProxy from "../../../proxies/sms";
import useDeleteSms from "../../../requests/communication/sms/useDeleteSms";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import useValidSms from "../../../requests/communication/sms/useValidSms";
import useInvalidSms from "../../../requests/communication/sms/useInvalidSms";
import useSendForValidationDataTable from "../../../requests/communication/sms/useSendForValidationDataTable";
import SendModal from "./SendModal/SendModal";
import ResponseModal from "./ResponseModal/ResponseModal";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import toast from "react-hot-toast";
import useChangeCUSms from "../../../requests/utilisateur/useChangeCUSms";
import TarifsModal from "./TarifsModal/TarifsModal";
import publicationMobileProxy from "../../../proxies/publicationMobile";

function SmsView() {
    const queryClient = useQueryClient();
    const deleteSms = useDeleteSms();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [searchFilter] = useDebounce(search, 300);
    const validSms = useValidSms();
    const invalidSms = useInvalidSms();
    const sendForValidation = useSendForValidationDataTable();
    const snapAuth = useSnapshot(auth);
    const changeCU = useChangeCUSms({
        onSuccess: (res) => {
            if (res.accepteConditionUtilisationSms === true){
                auth.utilisateur.accepteConditionUtilisationSms = true;
                toast.success('Les conditions d\'utilisation ont été acceptés', {
                    duration:5000
                });
            }else {
                auth.utilisateur.accepteConditionUtilisationSms = false;
                toast.error('Les conditions d\'utilisation ont été refusés', {
                    duration:5000
                });
            }
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });
    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                if (snapAuth.tabIdSmsVerrou.indexOf((row.original.id)) !== -1){
                                    BtSwal.fire("Attention", "Le sms est verrouillé en édition par un autre utilisateur", "warning")
                                }else {
                                    smsProxy.showEditSms(row.original)
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                smsProxy.showSendSms(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen016 /></span>
                            Envoyer
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                smsProxy.showReponseSms(row.original)
                            }}
                        >
                            <span className='fa fa-lg fa-eye svg-icon svg-icon-1 me-2'></span>
                            Voir les réponses
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        {row.original.isValidePourEnvoi
                            ? <Dropdown.Item className='menu-link px-3' onClick={() => {
                                invalidSms.mutate(row.original.id)
                            }} > <span className='svg-icon svg-icon-1 me-2'><Gen034 /></span> Invalider le sms </Dropdown.Item>
                            : <Dropdown.Item className='menu-link px-3' onClick={() => {
                                validSms.mutate(row.original.id)
                            }}><span className='svg-icon svg-icon-1 me-2'><Gen037 /></span>Valider le sms</Dropdown.Item>
                        }

                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                BtSwal.fire({
                                    title: 'Êtes vous sur de vouloir supprimer le sms ?',
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteSms.mutate(row.original.id);
                                        BtSwal.fire('Le sms a été supprimé!', '', 'success')
                                    } else if (result.isDenied) {
                                        BtSwal.fire('Le sms n\'a pas été supprimé', '', 'info')
                                    }
                                })
                            }
                        }
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            width: 80,
            Cell:({value, row}) => {
                if (snapAuth.tabIdSmsVerrou.indexOf((row.original.id)) !== -1){
                    return <span className="d-flex align-items-center">{value} <span className="ms-4 badge badge-circle badge-secondary"></span></span>
                }
                return value;
            }
        },
        {
            Header: 'Nom',
            accessor: 'nom',
            minWidth: 200,
        },
        {
            Header: "Prêt à l'envoi",
            accessor: 'isValidePourEnvoi',
            minWidth: 180,
            maxWidth: 180,
            Cell: ({ value, row }) => {
                if (value == null)
                    return '';

                return <><div className="btn-group" data-kt-buttons="true"
                              data-kt-buttons-target="[data-kt-button]">
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!value ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                invalidSms.mutate(row.original.id)
                            }}
                            className="btn-check" type="radio" name="method" value={value.id}/>
                        <span style={{fontSize: '10px'}}>A valider</span></label>
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                validSms.mutate(row.original.id)
                            }}
                            className="btn-check" type="radio" name="method" value={value.id}/>
                        <span style={{fontSize: '10px'}}>Validé</span></label>
                </div>
                    <span
                        onClick={() => {
                            sendForValidation.mutate(row.original.id)
                        }}
                        title='Envoyer pour validation' className='ms-2 cursor-pointer'
                    ><span className='svg-icon svg-icon-fluid me-2 w-30px d-inline-block'><MemoCicle /></span>
                        </span>
                </>
            },
        },
        {
            Header: "Envoi programmé le",
            accessor: 'dateProgrammationEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            Header: "Date d'envoi",
            accessor: 'dateEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            Header: 'Date de création',
            accessor: 'dateCreation',
            minWidth: 165,
            maxWidth: 165,
            Cell: ({ value }) => {
                if (value == null) return '';
                return moment(value * 1000).format("DD/MM/YYYY à HH:mm");
            },
        },
    ], [snapAuth.tabIdSmsVerrou]);

    // if (headers.isLoading || footers.isLoading || categories.isLoading || expediteurs.isLoading){
    //     return <LoadingView/>
    // }

    if (!snapAuth.utilisateur?.accepteConditionUtilisationSms){
        return <div className="card-body">
            <div style={{textAlign :"justify"}} className="p-5 text-justify">
                <div className="mb-10">
                    <h1 className="fs-2x text-dark mb-7 text-center">Conditions d'utilisation des sms</h1>
                    <p>RG Développement propose à ses clients un service de communication par messagerie mobile dans les conditions prévues ci-après.</p>
                    <p><b>VOUS RECONNAISSEZ QUE LES PRÉSENTES CONDITIONS GÉNÉRALES SONT EXÉCUTOIRES ET QUE VOUS LES ACCEPTEZ. À DÉFAUT, VOUS N’ÊTES PAS AUTORISÉ À UTILISER LE SERVICE DE COMMUNICATION PAR MESSAGERIE MOBILE.</b></p>
                    <h2>1. Définitions</h2>
                    <p>
                        <ul>
                            <li>
                                <p><b>Client :</b> toute personne physique ou morale commandant le droit d’utiliser le service de communication par messagerie mobile à des fins professionnelles et s’engageant selon les présentes Conditions Générales, incluant les utilisateurs personnes physiques qui utilisent les Services au nom et pour le compte du Client.</p>
                            </li>
                            <li>
                                <p><b>Conditions Générales ou Contrat :</b> les présentes conditions générales de vente et d’utilisation du service de communication par messagerie mobile et tout document annexé ou visé dans les présentes Conditions Générales, notamment les Conditions Particulières applicables.</p>
                            </li>
                            <li>
                                <p><b>Conditions Particulières :</b> les conditions particulières souscrites par le Client, notamment  considérées comme annexées aux présentes Conditions Générales.</p>
                            </li>
                            <li>
                                <p><b>Destinataires :</b> personnes auxquelles le Client adresse un Message dans le cadre de l’utilisation du service de communication par messagerie mobile.</p>
                            </li>
                            <li>
                                <p><b>Données :</b> donnée ou information utilisée ou créée par le Client dans le cadre de l’utilisation du service de communication par messagerie mobile, y compris les Données Personnelles.</p>
                            </li>
                            <li>
                                <p><b>Données Personnelles :</b> données à caractère personnel permettant d’identifier directement ou indirectement une personne physique au sens de la réglementation en vigueur.</p>
                            </li>
                            <li>
                                <p><b>Messages :</b> messages ou communications électroniques pouvant être envoyés par le Client par l’intermédiaire du service de communication par messagerie mobile fournis par Rg Développement, tels que décrits dans les Conditions Particulières applicables.</p>
                            </li>
                            <li>
                                <p><b>RG Développement :</b> société RG Développement, société à responsabilité limitée, dont le siège social est situé à 30 rue Théron de Montaugé – 31200 Toulouse, immatriculée au Registre du Commerce et des Sociétés de Toulouse sous le numéro 804 285 492 représentée par Vincent Fournier.</p>
                            </li>
                        </ul>
                    </p>
                    <h2>
                        2. Objet et application des Conditions Générales
                    </h2>
                    <p>Sauf conditions particulières expressément acceptées par écrit par RG Développement, ces Conditions Générales ont pour objet de définir les modalités et conditions d’utilisation du service de communication par messagerie mobile par le Client.</p>
                    <p>L’utilisation du service de communication par messagerie mobile est subordonnée à l’acceptation et au respect de ces Conditions Générales (y compris des Conditions Particulières applicables).
                        L’acceptation des Conditions Générales (y compris des Conditions Particulières applicables) par un de ses préposés ou collaborateurs est réputée faite au nom et pour le compte du Client par une personne dûment habilitée.</p>
                    <h2>3. Description du service de communication par messagerie mobile</h2>
                    <p>La description du service de communication par messagerie mobile souscrit par le Client est précisée dans les Conditions Particulières applicables.</p>
                    <p>Afin de pouvoir utiliser le service de communication par messagerie mobile, le Client doit obligatoirement :</p>
                    <ul>
                        <li>Avoir un accès à l’application Partenaire Link ;</li>
                        <li>Respecter les autres prérequis éventuels précisés dans les Conditions Particulières ou communiqués par RG Développement </li>
                    </ul>
                    <p>D’une manière générale, l’ensemble des moyens techniques, ainsi que les frais de télécommunication permettant l’accès et l’utilisation du service de communication par messagerie mobile sont à la charge exclusive et sous la seule responsabilité du Client. Le Client doit disposer des équipements et moyens de télécommunication nécessaires à leur utilisation. Sauf disposition contraire écrite, les Services n’incluent pas l’installation, la configuration, l’adaptation, la personnalisation, ni l’adéquation du service de communication par messagerie mobile aux besoins spécifiques du Client, ni de prestations de formation.</p>
                    <p>L’accès au service de communication par messagerie mobile par le Client se fait par le biais des accès à l’application Partenaire Link, qu’il ne divulguera pas et qui sont strictement personnels. Le Client est seul responsable de l’utilisation de ces identifiants par des tiers, et garantit à ce titre RG Développement contre toute demande et/ou action basée sur une utilisation, frauduleuse ou non, de ces identifiants. Pour éviter une utilisation frauduleuse des identifiants, le Client s’engage à choisir un mot de passe permettant un niveau de sécurité élevé selon les standards et recommandations actuellement en vigueur.</p>
                    <p>RG Développement se réserve le droit de faire évoluer le service de communication par messagerie mobile, notamment en mettant à disposition de nouvelles fonctionnalités, ou en modifiant ou supprimant certaines fonctionnalités ou en changeant les tarifs applicables après information du Client. RG Développement communiquera au Client, avec un délai d’un mois, la description synthétique des évolutions majeures.</p>
                    <p>Tout autre service qui serait éventuellement fourni au Client devra faire l’objet d’un accord écrit spécifique.</p>
                    <p>L’utilisation du service de communication par messagerie mobile n’est assortie d’aucune autre garantie quelle qu’elle soit que celles concédées expressément aux présentes.</p>
                    <h2>4. Conditions financières</h2>
                    <p>Toute contestation éventuelle de facture devra être faite par écrit et motivée dans un délai de 15 jours à compter de la réception de la facture. À défaut, la facture sera réputée acceptée par le Client.</p>
                    <p>Tout retard de paiement par rapport à la date d’échéance convenue donnera lieu à l’application de pénalités égales à un taux annuel de 10 % par jour de retard et d’une indemnité pour frais de recouvrement d’un montant minimum forfaitaire de 40 €, sans qu’il soit besoin d’aucune mise en demeure préalable, et sans que cette clause fasse obstacle à toute action ayant pour objet le recouvrement de la créance. Tous les frais entraînés par cette carence seront ou resteront à la charge du débiteur défaillant. En outre, en cas de retard de paiement, RG Développement pourra suspendre ou résilier le contrat.</p>
                    <h2>5. Durée et résiliation du Contrat</h2>
                    <p>La durée du Contrat et les conditions de résiliation sont décrites dans les Conditions Particulières applicables aux Services souscrits.</p>
                    <p>De façon générale, le Contrat pourra être résilié (pour quelque cause que ce soit) dans sa globalité.</p>
                    <p>La fin du Contrat, quelle que soit la raison, anticipée ou à son terme, emportera cessation du droit d’utiliser le service de communication par messagerie mobile. Le Client fera son affaire personnelle de la récupération des Données, étant précisé que la suppression de ces Données interviendra automatiquement 3 mois après la résiliation du Contrat sauf obligation légale contraire.</p>
                    <ul>
                        <li>
                            <b>Résiliation anticipée :</b>
                            <p>dans le cas où une partie contreviendrait aux dispositions des Conditions Générales et/ou des Conditions Particulières, la partie plaignante pourra résilier le contrat, automatiquement, de plein droit et sans aucune formalité, 30 (trente) jours après une mise en demeure par lettre recommandée avec demande d’avis de réception demeurée sans effet ou, le cas échéant, immédiatement si le manquement invoqué n’est pas susceptible de remède. </p>
                        </li>
                        <li>
                            <b>Suspension :</b>
                            <p>Par ailleurs, RG Développement se réserve le droit, conformément aux stipulations figurant aux présentes de suspendre de plein droit le droit d’accès du Client aux Services, sans préavis ni indemnité dans les cas suivants :</p>
                            <ul>
                                <li>Un manquement du Client (ou l’un des utilisateurs) à l’article 6 ou aux Conditions Particulières applicables ;</li>
                                <li>Un quelconque acte de piratage ou de tentative d’utilisation illicite des informations circulant sur le réseau a pour cause ou origine le compte du Client ; </li>
                                <li>Les informations concernant le Client telles que communiquées lors de son inscription par celui-ci ne correspondent pas à la réalité ; </li>
                                <li>Le non-paiement de tout ou partie des sommes dues par le Client à RG Développement après la date d’échéance ; </li>
                                <li>En application d’une injonction ou disposition légale ou réglementaire applicable à RG Développement ou l’un de ses fournisseurs ; </li>
                                <li>En cas de nécessité à des fins de maintenance ou de sécurité (dont RG Développement s’efforcera, si possible, d’avertir le Client) ; </li>
                                <li>En cas de suspension de la fourniture du service de communication par messagerie mobile par le(s) fournisseurs de RG Développement. </li>
                            </ul>
                        </li>
                    </ul>
                    <p>Cette suspension sera applicable sans préjudice des autres droits de RG Développement et sans indemnité.</p>
                    <h2>6. Obligations du Client</h2>
                    <p>Les Services sont destinés à un usage professionnel. Le Client doit être majeur et être capable de fournir des justificatifs de son appartenance à une société en cas de demande de la part de RG Développement.</p>
                    <p>Le Client s’engage à respecter les recommandations de l’Autorité de Régulation Professionnelle de la Publicité (ARPP) s’agissant des règles régissant les campagnes publicitaires. A cette fin, le Client doit notamment fournir des informations claires et non équivoques, lisibles et/ou audibles permettant :</p>
                    <ul>
                        <li>L’identification de la publicité ;</li>
                        <li>L’identification de l’annonceur.</li>
                    </ul>
                    <p>Le Client est informé que les opérateurs de télécommunication peuvent bloquer des envois qui ne respectent pas les règles définies par l’ARPP et/ou lorsque des sms frauduleux (usurpation d’identité, phishing, smishing et autres) sont détectés. RG Développement ne saurait être tenue responsable de tels blocages que les opérateurs de téléphonie peuvent mettre en œuvre sans consultation préalable de RG Développement ou du Client.</p>
                    <p>Le Client s’engage par ailleurs à utiliser le service de communication par messagerie mobile conformément aux réglementations nationales et internationales, et en particulier à ne pas les utiliser pour afficher, envoyer ou transmettre tout contenu :</p>
                    <ul>
                        <li>À caractère violent ou susceptible de porter atteinte au respect et à la dignité de la personne humaine, à l’égalité entre les hommes et les femmes, à la protection des enfants et des adolescents, notamment par la fabrication, le transport et la diffusion de messages à caractère violent ou pornographique ou de nature à porter atteinte à la dignité humaine ; </li>
                        <li>Qui encourage à la commission de crimes et délits ; </li>
                        <li>Qui incite à la consommation de substances interdites ; </li>
                        <li>Qui provoque ou puisse provoquer la discrimination, la haine, la violence en raison de la race, de l’ethnie ou de la nation ; </li>
                        <li>Qui soit illégal, nuisible, menaçant, abusif, constitutif de harcèlement, diffamatoire, injurieux, vulgaire, obscène, menaçant pour la vie privée d’autrui ou de nature à heurter la sensibilité de certaines personnes ; </li>
                        <li>Qui induise en erreur en usurpant le nom ou la dénomination sociale d’autres personnes ; </li>
                        <li>Qui porte atteinte aux droits de tiers comme, sans que cette liste ne soit limitative, à tout secret de fabrication, secret professionnel, information confidentielle, marque, brevet et d’une manière générale tout droit de propriété industrielle ou intellectuelle ou tout autre droit portant sur une information ou un contenu protégé ; </li>
                        <li>Comprenant, sans que cette liste ne soit limitative, des virus informatiques ou tout autre code ou programme, conçus pour interrompre, détruire ou limiter la fonctionnalité de tout logiciel, ordinateur ou outil de télécommunication ou constitutifs de spams. </li>
                    </ul>
                    <p>La prospection directe par SMS est régie par plusieurs règles et textes de loi:</p>
                    <ul>
                        <li>L’article 22 de la LCEN (Loi pour la Confiance dans l’Économie Numérique) du 22 juin 2004, basée sur la Directive Européenne du 12 juillet 2002, modifiant les articles L.33-4-1 du code des Postes et Télécommunications et L120-20-5 du code de la consommation</li>
                        <li>La loi « Informatique et Libertés » du 6 janvier 1978 de la CNIL (modifiée par la loi du 30 janvier 2002)</li>
                        <li>L’article L. 34-5 du Code des Postes et Communications Électroniques</li>
                        <li>Le Règlement Général sur la Protection des Données (RGPD ou GDPR en anglais)</li>
                    </ul>
                    <p>L’envoi de SMS commerciaux est formellement interdit pendant les heures comprises entre 20h00 et 08h00 les jours calendaires, le dimanche toute la journée et les jours fériés.</p>
                    <p>En cas de non respect des dispositions légales, les sanctions pénales peuvent être lourdes, jusqu’à 1 000€ HT par manquement constaté selon l’opérateur.</p>
                    <p>D’une manière générale, le Client s’engage à respecter les Conditions Générales et Conditions Particulières applicables, à ne pas porter atteinte de quelque manière que ce soit, à tout droit de tiers, à ne pas utiliser ou diffuser de contenus prohibés par la loi et à ne pas entraver ni perturber le fonctionnement du service de communication par messagerie mobile.</p>
                    <p>Le Client reconnaît et accepte qu’il est responsable des Données et des contenus créés, stockés ou diffusés dans le cadre de l’utilisation du service de communication par messagerie mobile, à l’exclusion de toute responsabilité de RG Développement ou de ses fournisseurs.</p>
                    <p>Le Client s’engage notamment à n’utiliser le service de communication par messagerie mobile qu’à l’égard de Données Personnelles concernant les Destinataires qu’il est autorisé à traiter, dans le respect de la réglementation en vigueur notamment concernant les Données Personnelles, la collecte du numéro de téléphone et la prospection ou l’envoi de Messages et, le cas échéant, avec le consentement du Destinataire. Il s’engage également à utiliser de manière obligatoire des dispositifs permettant au Destinataire de s’opposer à la réception de Messages de prospection ou, si applicable, au traitement de leurs Données Personnelles. Un sms de confirmation de désinscription est obligatoire sur le canal marketing. Il est envoyé automatiquement depuis le compte émetteur du message marketing, à la charge du Client.</p>
                    <p>Si le Client a connaissance d’une violation probable ou avérée du présent article ou des Conditions Particulières applicables, le Client s’engage à en informer RG Développement par écrit. De même, le Client s’engage à informer RG Développement par écrit de toute demande ou communication de la part des autorités compétentes concernant directement ou indirectement la fourniture ou l’utilisation du service de communication par messagerie mobile en adressant une copie de ladite demande ou communication et/ou toutes les informations concernant celle-ci.</p>
                    <p>Le Client s’engage à n’utiliser le service de communication par messagerie mobile que pour ses besoins propres et notamment à ne pas revendre tout ou partie du service de communication par messagerie mobile, seuls ou avec d’autres services, au bénéfice de tiers, sauf accord explicite de RG Développement. Le Client garantit que les données qu’il communique sont exactes et conformes à la réalité. Il s’engage à modifier ses informations sur son compte en cas de changement.</p>
                    <p>Le Client s’engage à garantir RG Développement et ses fournisseurs de tout dommage direct ou indirect résultant de la violation des Conditions Générales ou aux Conditions Particulières applicables par le Client, notamment de toute action qui serait engagée à son encontre, ou toute plainte qui serait déposée contre elle, par un tiers, du fait de l’utilisation par le Client, ou sous le contrôle de son identifiant, du service de communication par messagerie mobile dans des conditions qui ne seraient pas conformes aux Conditions Générales, aux Conditions Particulières applicables ou à la loi. Cette garantie couvre toute somme que RG Développement (ou ses fournisseurs) serait tenue de verser à quelque titre que ce soit, y compris les honoraires d’avocat et frais de justice.</p>
                    <h2>7. Obligations de RG Développement</h2>
                    <p>RG Développement s’engage à mettre à disposition et à fournir le service de communication par messagerie mobile conformément aux dispositions légales et réglementaires françaises et européennes en vigueur, aux usages et règles de la profession de services informatiques.</p>
                    <p>En particulier, RG Développement s’engage à :</p>
                    <ul>
                        <li>Respecter les obligations légales propres au secret des correspondances émises par voie de télécommunication et dont la violation est sanctionnée par les dispositions des articles L.226-15 et 432-9 du Code Pénal, sous réserve notamment des dispositions de la loi n° 91-646 du 10 juillet 1991 ; </li>
                        <li>Assurer la sécurité, la confidentialité et l’intégrité des Messages et des Données Personnelles concernant les Destinataires communiqués par le Client dans les conditions Particulières applicables ; </li>
                        <li>Fournir le service de communication par messagerie mobile et le rendre accessible 24/24h, 7/7j, sauf interruption, programmée ou non, pour les besoins de sécurité, de maintenance ou cas de force majeure. </li>
                    </ul>
                    <p>Le Client déclare avoir pris connaissance des caractéristiques et délais d’acheminement spécifiques du service de communication par messagerie mobile proposé par RG Développement.</p>
                    <p>Les obligations de RG Développement sont des obligations de moyens. En particulier, RG Développement s’engage par ailleurs à mettre tous les moyens disponibles en œuvre pour respecter ces délais, et à signaler tout dysfonctionnement au Client dans les meilleurs délais.</p>
                    <h2>8. Données Personnelles</h2>
                    <p>Les dispositions applicables au traitement des Données Personnelles des Destinataires par le Client (responsable de traitement) et par RG Développement (sous-traitant) sont prévues à l’Annexe “Données Personnelles” à la fin de ces Conditions Générales.</p>
                    <p>Les dispositions applicables au traitement des Données Personnelles fournies par le Client par RG Développement (responsable de traitement) pour l’exécution des présentes Conditions Générales figurent dans la politique de confidentialité de RG Développement disponible sur le Site Internet.</p>
                    <h2>9. Confidentialité et propriété intellectuelle</h2>
                    <p>En complément des dispositions applicables en matière de Données Personnelles, les parties s’engagent à garder confidentielle et à ne pas utiliser à leur propre compte toute information de nature confidentielle, y compris, mais sans s’y limiter, tout secret commercial, toute information exclusive, tout détail commercial et financier et toute autre information ayant une valeur commerciale (ci-après “Information Confidentielle”).</p>
                    <p>Le présent article ne s’applique pas aux Informations Confidentielles dont la partie destinataire peut démontrer qu’elles sont dans le domaine public ou qu’elle en avait déjà connaissance au moment de leur divulgation, qu’elles tombent ensuite dans le domaine public autrement que par une violation de la présente clause ou qu’elles sont légalement en possession de la partie destinataire en provenance d’un tiers.</p>
                    <p>Si une autorité ou un tribunal demande des Informations Confidentielles, les parties se contacteront et coopéreront pour minimiser les effets négatifs éventuels d’une telle divulgation.</p>
                    <p>Le Client reconnaît que, si une autorité ou un tribunal demande à RG Développement ou à l’un de ses fournisseurs de divulguer des informations relatives aux Client ou l’utilisation du Service, RG Développement ou le fournisseur sera tenu de s’y conformer et de fournir ces informations.</p>
                    <p>La partie destinataire retournera à la partie divulgatrice toutes les Informations Confidentielles que la partie destinataire détient sous quelque forme que ce soit à la résiliation ou à l’expiration du Contrat.</p>
                    <p>Les termes de la présente clause survivront à l’expiration ou à la résiliation anticipée du Contrat, tant que l’Information Confidentielle ne sera pas tombée dans le domaine public.</p>
                    <p>Par ailleurs, il est précisé que les droits de propriété intellectuelle sur tous les logiciels, informations, technologies ou données, quels qu’ils soient, fournis par l’une ou l’autre des parties dans le cadre du Contrat restent la propriété de cette partie.</p>
                    <h2>10. Responsabilités</h2>
                    <ul>
                        <li>
                            <b>Client :</b> le Client utilise le service de communication par messagerie mobile sous son entière et exclusive responsabilité. En particulier, le Client est seul responsable de l’usage qu’il fait du service de communication par messagerie mobile et des Données qu’il utilise ou communique au travers du service de communication par messagerie mobile.
                        </li>
                        <li>
                            <b>RG Développement :</b> les parties reconnaissent que, du fait de la nature complexe des réseaux de télécommunications et du service de communication par messagerie mobile, ce dernier est susceptible de présenter des dysfonctionnements non détectés ou non identifiés. Le Contrat est conclu entre les parties en pleine connaissance de l’éventuelle existence de tels dysfonctionnements. RG Développement n’est donc tenue qu’à une obligation de moyens et ne pourra en conséquence voir sa responsabilité engagée que si la preuve est apportée qu’elle n’a pas mis l’ensemble de ses moyens à la disposition du Client en vue de mettre à disposition et fournir le service de communication par messagerie mobile conformément aux présentes Conditions Générales et aux Conditions Particulières applicables. Étant soumis à une obligation de moyens, RG Développement ne saurait être tenue responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du service de communication par messagerie mobile non dû à une faute prouvée de RG Développement.
                            En aucun cas, RG Développement ne pourra être tenue envers le Client, de tous dommages indirects notamment mais sans limitation, tout manque à gagner, pertes d’exploitation, de bénéfices, données, enregistrements ou contenus, toute interruption d’activité ou de l’impossibilité d’y accéder en dehors des cas prévus aux Conditions Générales et Conditions Particulières applicables.
                            Le montant des dommages pour lesquels RG Développement pourrait être tenue responsable, dans les conditions prévues ci-dessus, sera limité à la somme que le Client a effectivement payée au titre de la fourniture du service de communication par messagerie mobile au cours de la période de douze (12) mois qui a précédé l’évènement qui est à l’origine du dommage.
                            Toute réclamation au titre de l’exécution, l’inexécution ou la mauvaise exécution d’un Service devra faire l’objet d’une notification écrite du Client à RG Développement dans un délai de 3 mois à compter de l’exécution dudit service ou de la date de l’évènement qui est à l’origine du dommage.
                        </li>
                        <li>
                            <b>Force majeure :</b> aucune partie ne sera responsable d’un manquement à ses obligations aux termes des présentes si un tel manquement résulte d’un acte, fait ou événement indépendant de sa volonté, plus particulièrement par suite d’un cas de force majeure, et ce compris notamment les événements reconnus comme tels par la jurisprudence, et, sans limitation : grèves, guerres, embargos à l’importation ou à l’exportation, “lock-outs”, accidents, incendies, blocus, catastrophes naturelles, pandémie, perturbations, coupures et anomalies affectant les transmissions au travers des réseaux de télécommunications, retards, suspensions ou interruptions de service des opérateurs de téléphonie mobile, ordre de l’Autorité de Régulation des Télécommunications imposant la suspension totale ou partielle de radiotéléphonie publique GSM, coupures de courant ou toute autre difficulté technique ou dysfonctionnement du réseau Internet.
                        </li>
                        <li>
                            <b>Indemnisation :</b> d’une manière générale, chaque partie indemnisera l’autre partie contre toutes les réclamations déposées par un tiers en rapport avec la fourniture ou l’utilisation du service de communication par messagerie mobile dans le cadre du présent Contrat, à condition que la partie demandant l’indemnisation (i) notifie immédiatement à l’autre partie cette réclamation de tiers, (ii) concède à l’autre partie le contrôle de la défense à ladite réclamation, (iii) n’entre pas dans des négociations ou transaction sans l’accord de l’autre Partie et (iv) coopère à tous égards avec l’autre partie dans la défense à la réclamation.
                        </li>
                    </ul>
                    <h2>11. Loi, litiges & attribution de compétence</h2>
                    <p>La loi applicable est la loi française. Dans les rapports avec le Client, seule la version française du Contrat fait foi.</p>
                    <p>En cas de difficulté relative à la formation, l’interprétation, l’application ou la résiliation des Conditions Générales ou du Contrat entre les parties, celles-ci tenteront de résoudre leur litige de manière amiable. À défaut de résolution amiable dans un délai d’un mois à compter de la notification du litige par une partie à l’autre partie, le litige devra être soumis au Tribunal de commerce de Toulouse, auquel il est fait expressément attribution de compétence, même en cas de pluralité de défendeurs ou d’appel en garantie, procédures d’urgence ou procédures conservatoires dans le délai d’un an à compter du jour où la partie à compter du jour où la partie engageant l’action a connu ou aurait dû connaître les faits à l’origine de celle-ci. Le préalable de conciliation ne sera pas applicable en cas de référé ou d’urgence.</p>
                    <h2>12. Divers</h2>
                    <p>Si l’une quelconque des clauses du Contrat s’avérait nulle ou inapplicable en vertu d’une loi ou d’un règlement ou à la suite d’une décision exécutoire d’une juridiction ou d’une autorité compétente, les parties conviennent expressément que le contrat ne sera pas affecté par la nullité de la clause précitée.</p>
                    <p>Le fait pour l’une des parties de ne pas exiger à un moment quelconque l’exécution stricte par l’autre partie d’une disposition ou condition quelconque des présentes Conditions Générales ne sera pas réputé constituer une renonciation définitive à cette disposition ou condition. En conséquence, chacune des parties pourra à tout moment demander l’exécution stricte et intégrale par l’autre partie du Contrat.</p>
                    <h2>CONDITIONS PARTICULIÈRES DE VENTE ET D’UTILISATION
                        DU SERVICE D’ENVOI DE SMS</h2>
                    <p>Les présentes Conditions Particulières complètent les conditions générales de vente et d’utilisation RG Développement (“Conditions Générales” ou CGVU) et sont applicables à l’utilisation du service d’envoi de SMS.</p>
                    <p><b>VOUS RECONNAISSEZ QUE LES PRÉSENTES CONDITIONS PARTICULIÈRES SONT EXÉCUTOIRES ET QUE VOUS LES ACCEPTEZ. À DÉFAUT, VOUS N’ÊTES PAS AUTORISÉ À UTILISER LE SERVICE D’ENVOI DE SMS DE RG DEVELOPPEMENT.</b></p>
                    <h2>1. Objet et application des Conditions Particulières</h2>
                    <p>Ces Conditions Particulières ont pour objet de définir les modalités et conditions d’utilisation des Services d’envoi de Short Message Service (SMS) fournis par RG Développement au Client en complément des Conditions Générales.</p>
                    <p>L’utilisation du service de communication par messagerie mobile est subordonnée à l’acceptation et au respect ces Conditions Générales et des Conditions Particulières.</p>
                    <h2>2. Description du service de communication par messagerie mobile – Précisions apportées à l’article 3 des CGVU</h2>
                    <p>Le service de communication par messagerie mobile permettent au Client d’envoyer des SMS vers le téléphone mobile du correspondant de son choix, le(s) Destinataire(s), en envoi immédiat ou différé, unique ou en nombre.</p>
                    <p>Selon le type de SMS utilisé et selon le souhait du Client, un compte-rendu de réception du SMS envoyé peut être obtenu et, pour le SMS uniquement, l’émetteur peut être personnalisé.</p>
                    <p>Le Site Internet RG Développement met aussi à disposition un système de carnet d’adresses permettant de gérer des listes de contacts, utilisables lors de la programmation des envois, une liste rouge permettant de stocker les numéros des Destinataires ne souhaitant pas recevoir de SMS de la part du Client.</p>
                    <p>Un traitement des numéros en doublons peut être effectué sur la base du carnet d’adresses.</p>
                    <p>Par ailleurs, l’identification d’éventuels doublons est systématiquement réalisée lors de l’envoi d’un SMS afin d’effectuer un envoi de SMS unique par Destinataire.</p>
                    <h2>3. Conditions financières – Précisions apportées à l’article 4 des CGVU</h2>
                    <p>Le Client s’engage à payer la somme due à RG Développement au titre des SMS consommés pendant la période mensuelle écoulée, telle que mentionné dans la facture établie par RG Développement.</p>
                    <p>Tout SMS consommé est définitif et le Client ne pourra en aucun cas en demander le remboursement partiel ou total.</p>
                    <p>RG Développement se réserve le droit de modifier à tout moment les prix des SMS et n’offrira aucune réduction de prix ni ne procédera à aucun remboursement en cas de réduction de prix ou d’offre promotionnelle ultérieure à la date d’achat des SMS. </p>
                    <h2>4. Durée et résiliation du Contrat – Précisions apportées à l’article 5 des CGVU</h2>
                    <p>Le contrat est conclu lors de l’acceptation des Conditions Générales et des Conditions Particulières, pour une durée indéterminée. Chacune des deux parties pourra résilier unilatéralement le contrat sans avoir de justification à donner par lettre avec accusé de réception avec un préavis d’un mois. </p>
                    <h2>5. Obligations du Client – Précisions apportées à l’article 6 des CGVU</h2>
                    <p>En complément des obligations définies à l’article 6 des Conditions Générales, le Client est informé que le STOP SMS est un outil qui permet aux destinataires des SMS envoyés par le Client de se désinscrire de la liste de diffusion. Son utilisation est obligatoire pour tout envoi de type prospection, campagne commerciale ou campagne promotionnelle conformément à l’Article L.121-20-5 du Code de la consommation. Le Client devra retirer les Destinataires de la liste d’envoi en cas d’opposition à la réception des SMS ou au traitement de leurs données</p>
                    <h2>6. Obligation de RG Développement – Précisions apportées à l’article 7 des CGVU</h2>
                    <p>En complément des obligations stipulées à l’article 7 des Conditions générales, le Client est informé que les messages seront considérés comme envoyés au mobile du Destinataire lorsqu’ils seront transmis à l’opérateur mobile directement ou via une interface fournie par l’opérateur. RG Développement ne garantit pas la livraison de la part de l’opérateur mobile.</p>
                    <p>De plus, chaque SMS a une durée de validité de 24 heures à compter de la date d’envoi de celui-ci. Au bout de ces 24 heures, le SMS sera alors considéré comme non livrable et aucune nouvelle tentative de livraison ne sera effectuée.</p>
                    <h2>ANNEXE</h2>
                    <h2>Traitement des données personnelles par RG Développement en qualité de sous-traitant</h2>
                    <p>La présente annexe a pour objet de définir les conditions dans lesquelles RG Développement s’engage à effectuer pour le compte du Client les opérations de traitement des Données Personnelles des Destinataires dans le cadre de l’exécution du service de communication par messagerie mobile.</p>
                    <p>Dans le cadre de leurs relations contractuelles, les parties s’engagent à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après “RGPD”).</p>
                    <p>RG Développement s’efforce de prendre en compte les principes de protection des données dès la conception et de protection des données par défaut dans le cadre du développement de la Solution.
                        La présente annexe s’applique en complément et sans préjudice des Conditions Générales et Conditions Particulières applicables. Les mots utilisés avec une majuscule sont définis dans les Conditions Générales et Conditions Particulières applicables.</p>
                    <h2>1. Description du traitement</h2>
                    <p>En application de l’article 28.3 du RGPD, le traitement des Données Personnelles dans le cadre de l’utilisatipon du service de communication par messagerie mobile par le Client est décrit ci-après.</p>
                    <p>L’utilisation du service de communication par messagerie mobile nécessite le traitement de Données Personnelles concernant les Destinataires des Messages, pour lequel le Client est le responsable de traitement et RG Développement est le sous-traitant.</p>
                    <p><b>Types de données à caractère personnel </b>les Données Personnelles des Destinataires traitées par RG Développement pour le compte du Client sont les suivantes :</p>
                    <ul>
                        <li>Au minimum, de manière obligatoire : numéro de téléphone mobile du Destinataire et Message à envoyer, </li>
                        <li>De manière facultative au choix du Client : civilité, prénom, nom du Destinataire. </li>
                    </ul>
                    <p><b>Catégories de personnes concernées :</b> Destinataires des Messages.</p>
                    <p><b>Nature et finalités du traitement :</b> collecte et gestion des coordonnées des Destinataires et des Messages déterminés par le Client afin de permettre l’envoi des dits Messages aux Destinataires.</p>
                    <p><b>Durée du traitement :</b> durée du contrat conclu entre RG Développement et le Client.</p>
                    <h2>2. Obligations du Client (responsable de traitement)</h2>
                    <p>Le Client s’engage à respecter ses obligations au titre de la réglementation applicable (en particulier concernant la protection des Données Personnelles, la collecte du numéro de téléphone et la prospection/l’envoi de Message), à l’égard des Destinataires.</p>
                    <p>Le Client s’engage à assurer la confidentialité des identifiants permettant d’accéder au service de communication par messagerie mobile et à mettre les mesures techniques et organisationnelles de sécurité appropriées pour l’utilisation du service de communication par messagerie mobile sur les matériels, logiciels et éléments relevant de sa responsabilité.</p>
                    <p>Plus généralement, les obligations du Client au titre de l’utilisation du service de communication par messagerie mobile sont prévues dans les Conditions Générales applicables.</p>
                    <h2>3. Obligations de RG Développement</h2>
                    <p>RG Développement a la qualité de sous-traitant au sens de la réglementation applicable.</p>
                    <p>À ce titre, en application de l’article 28.3 a) du RGPD, RG Développement ne traite les Données Personnelles que sur instruction documentée du Client, pour les finalités prévues dans la présente Annexe. La présente Annexe et les actions du Client dans le cadre de l’utilisation du service de communication par messagerie mobile constituent les instructions du Client.</p>
                    <p>Si RG Développement est tenue de procéder à un transfert de données vers un pays tiers ou à une organisation internationale, en vertu du droit de l’Union européenne ou du droit français, RG Développement en informera le Client, sauf interdiction pour des motifs importants d’intérêt public.</p>
                    <p>En application de l’article 28.3 b) du RGPD, RG Développement veille à ce que les personnes autorisées à traiter les Données Personnelles des Destinataires s’engagent à respecter la confidentialité de ces Données Personnelles et soient sensibilisées en matière de protection des Données Personnelles.</p>
                    <p>En application de l’article 28.3 c) du RGPD, RG Développement fait ses meilleurs efforts pour prendre toutes les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au traitement des Données Personnelles des Destinataires.</p>
                    <p>En application de l’article 28.3 e) du RGPD, RG Développement s’engage à collaborer dans la mesure du possible avec le Client pour répondre à toute demande d’un Destinataire dont les Données Personnelles sont utilisées dans le cadre du service de communication par messagerie mobile formulée conformément à la réglementation en vigueur. À ce titre, le Client est informé qu’il pourra lui-même rectifier ou supprimer les Données Personnelles des Destinataires sur son compte sur le Site Internet. RG Développement transmettra au Client toute demande d’un Destinataire qui lui parviendrait directement.</p>
                    <p>En application des articles 28.3 f) et h) du RGPD, le Client pourra demander à RG Développement les informations raisonnables ou rapports d’audit disponibles pour l’aider à garantir le respect par RG Développement de ses obligations. La documentation technique disponible est accessible sur le Site Internet. RG Développement fera ses meilleurs efforts pour informer, dans la mesure du possible, le Client si RG Développement a connaissance d’une instruction qui, selon elle, constitue une violation des dispositions applicables. RG Développement fera ses meilleurs efforts pour informer le Client de toute violation de Données Personnelles d’un Destinataire dans les meilleurs délais après en avoir pris connaissance.</p>
                    <p>Sous réserve du respect des obligations de confidentialité stipulées dans les Conditions Générales, le Client pourra demander un audit sur site des mesures de sécurités relatives à la protection des Données Personnelles du Client qui sera réalisé par le Client ou un auditeur tiers indépendant qu’il a mandaté (la désignation pourra faire l’objet d’un refus dûment motivé par RG Développement). Le Client devra informer RG Développement du nom de l’auditeur tiers concomitamment à la notification de la conduite de l’audit et en tout état de cause au moins 15 jours à l’avance. Le Client devra alors entrer en contact avec RG Développement afin de convenir des modalités de cet audit, c’est-à-dire la portée, le calendrier et la durée de l’audit. Le Client prendra en charge l’ensemble des coûts associés à cet audit. Les Parties conviennent d’un maximum d’un (1) audit par an limité à un maximum de cinq (5) jours ouvrables.</p>
                    <p>En application de l’article 28.3 g), RG Développement pourra restituer et supprimera les Données Personnelles des Destinataires hébergées dans le cadre de l’utilisation du service de communication par messagerie mobile, à la fin de la relation contractuelle.
                        Concernant la durée de conservation des historiques de messageries, les données resteront en base active pendant 6 mois. Une fois ce délai écoulé, les données seront archivées pendant 12 mois puis définitivement supprimées.</p>
                    <p>En application des articles 28.2, 28.3 d) et 28.4, le Client autorise RG Développement à recourir à des sous-traitants pour l’exécution du service de communication par messagerie mobile, pour lesquels RG Développement s’engage à leur demander le respect des obligations applicables aux Données Personnelles. RG Développement demeure responsable à l’égard du Client de l’exécution du contrat. La liste des sous-traitants est disponible sur simple demande du Client.</p>
                    <p>RG Développement informera préalablement le Client de tout changement prévu concernant l’ajout ou le remplacement d’autres sous-traitants, et le Client aura la possibilité d’émettre des objections dans un délai de 15 jours à l’encontre de ces changements en indiquant les raisons de ces objections. Dans ce cas, le Client pourra résilier le contrat.</p>
                </div>
                <div style={{bottom : "25px", right : "40%"}} className="position-fixed">
                    <div className="d-flex mt-3 justify-content-center">
                        <Button
                            onClick={() => {
                                changeCU.mutate(true);
                            }}
                            className="me-2">
                            J'accepte
                        </Button>
                        <Button
                            onClick={() => {
                                changeCU.mutate(false);
                            }}
                            variant={"danger"}
                        >
                            Je refuse
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    }else {
        return <>
            <Card>
                <Card.Body>
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <div className='mb-5'>
                            <Button
                                className="me-2"
                                variant={'secondary'}
                                onClick={() => {
                                    smsProxy.showTarifsSms();
                                }}
                            >
                                <span className='svg-icon svg-icon-1 m-0'><Pricing /></span>
                            </Button>
                            <Button variant='secondary' onClick={() => smsProxy.showEditSms(null)}>
                                <FontAwesomeIcon icon={faPlus} className='me-2' />
                                Créer un SMS
                            </Button>
                        </div>
                    </div>
                    <div>
                        <MetaTable
                            className='mt-5'
                            height={500}
                            url='sms'
                            keys={['sms']}
                            columns={columns}
                            search={searchFilter}
                        />
                    </div>
                </Card.Body>
            </Card>
            <EditModal />
            <SendModal/>
            <ResponseModal/>
            <TarifsModal/>
        </>;
    }

    function paramsIsOk(){
        return true;
        // let result = [];
        // let allIsOk = true;
        // //check header
        // if (headers.isSuccess && headers.data.nbLignesTotales == 0){
        //     result.push(paramPasOkLine("Entête de mail", <HeaderSettings />))
        //     allIsOk = false;
        // }else {
        //     result.push(paramOkLine("Entête de mail", <HeaderSettings />))
        // }
        // //check footer
        // if (footers.isSuccess && footers.data.nbLignesTotales == 0){
        //     result.push(paramPasOkLine("Signature de mail", <FooterSettings />))
        //     allIsOk = false;
        // }else {
        //     result.push(paramOkLine("Signature de mail", <FooterSettings />))
        // }
        // // check categorie
        // if (categories.isSuccess && categories.data.nbLignesTotales == 0){
        //     result.push(paramPasOkLine("Catégorie de mail", <CategorieMailSettings />))
        //     allIsOk = false;
        // }else {
        //     result.push(paramOkLine("Catégorie de mail", <CategorieMailSettings />))
        // }
        // // check expéditeur
        // if (expediteurs.isSuccess && expediteurs.data.nbLignesTotales == 0){
        //     result.push(paramPasOkLine("Exépditeurs de mail", <ExpediteurSettings />))
        //     allIsOk = false;
        // }else {
        //     result.push(paramOkLine("Expéditeurs de mail", <ExpediteurSettings />))
        // }
        // return allIsOk ? allIsOk : result;
    }

    // function paramOkLine(title, settingsConponent){
    //     return <tr className="text-center">
    //                 <td className="text-start ps-6">
    //                     <div className="fw-bold fs-4 text-gray-800">{title}</div>
    //                 </td>
    //                 <td>
    //                     <FontAwesomeIcon icon={faCheck} color={"green"}/>
    //                     <span
    //                         style={{fill: "black"}}
    //                         onClick={() => {
    //                         setCurrentComponentSettings(settingsConponent)
    //                         setShowModalSettings(true)
    //                     }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
    //                 </td>
    //             </tr>
    // }
    //
    // function paramPasOkLine(title, settingsConponent){
    //     return <tr className="text-center">
    //         <td className="text-start ps-6">
    //             <div className="fw-bold fs-4 text-gray-800">{title}</div>
    //         </td>
    //         <td>
    //             <FontAwesomeIcon icon={faTimes} color={"red"}/>
    //             <span
    //                 style={{fill: "black"}}
    //                 onClick={() => {
    //                 setCurrentComponentSettings(settingsConponent)
    //                 setShowModalSettings(true)
    //             }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
    //
    //         </td>
    //     </tr>
    // }
}


export default SmsView;