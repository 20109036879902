import {Card, Button} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import React, {useMemo, useRef} from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import useSendJustificatifsPaiement from "../../../requests/communication/invitations/useSendJustificatifsPaiement";
import useGetExportFacturation from "../../../requests/facturation/useGetExportFacturation";
import ExportFacturationModal from "./ExportFacturationModal";
import facturation from "../../../proxies/facturation";
import "flatpickr/dist/plugins/monthSelect/style.css"
import TelechargementFactureModal from "./TelechargementFactureModal";
import { ReactComponent as SuiviEmailBlack } from "@assets/icons/suiviEmailV2.svg";
import invitationsProxy from "../../../proxies/invitations";
import useChangeFacture from "../../../requests/communication/invitations/useChangeFacture";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useQueryClient} from "react-query";
import BlockUI from "../../../components/BlockUI/BlockUI";


function FacturationView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const sendJustificatifsPaiement = useSendJustificatifsPaiement();
    const urlExport = useGetExportFacturation();
    const queryClient = useQueryClient();
    const changeFacture = useChangeFacture({
        onSuccess: (res) => {
            queryClient.invalidateQueries('facture');

            toast.success("Facture générée.");
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la génération de la facture", err.message);
        },
    });

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => {
                if (row.original?.url){
                    return <span
                        style={{fill: "#211f1c"}}
                        title="Envoyer les justificatifs de paiement"
                        onClick={() => {
                            sendJustificatifsPaiement.mutate(row.original.correspondantInvitation.id)
                        }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><img width={25}
                                                                                    src="/icon/envelope-open-dollar.svg"
                                                                                    alt="bell"/></span>
                }else {
                    return <span
                        style={{fill: "#211f1c"}}
                        title="Générer la facture"
                        onClick={() => {
                            changeFacture.mutate({facture : row.original})
                        }} className='fa fa-2x fa-upload m-0 cursor-pointer'></span>
                }
            },
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'N° Facture',
            accessor: 'numero',
            Cell: ({row}) => {
                if (row.original?.url){
                    return <a target="_blank" href={row.original?.url}>{row.original?.numero}</a>
                }else {
                    return "Facture non générée"
                }
            },
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Date facture',
            accessor: 'date',
            // Cell: ({ row }) => {
            //     return  <a target="_blank" href={row.original?.url}>{row.original?.numero}</a>
            // },
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Personne',
            Cell: ({ row }) => {
                return row.original?.correspondantInvitation.destinataire.personne.prenom+" "+row.original?.correspondantInvitation.destinataire.personne.nom
            },
            accessor: "correspondantInvitation.destinataire.personne.prenomNom",
            minWidth: 200,
        },
        {
            Header: 'Entreprise',
            accessor: 'correspondantInvitation.destinataire.tabEntreprises.nom',
            minWidth: 100,
            noWrap:true,
            Cell: ({ row }) => {
                let entreprises = [];
                if (row.original?.correspondantInvitation?.destinataire?.tabEntreprises){
                    entreprises = Object.values(row.original?.correspondantInvitation?.destinataire?.tabEntreprises).map(e => e?.nom+"")
                }
                return entreprises.join(',\n');
            }
        },
        {
            Header: 'Accompagnants',
            accessor: 'correspondantInvitation.tabAccompagnants.destinataire.personne.prenomNom',
            minWidth: 100,
            noWrap: true,
            Cell: ({ row }) => {
                let accompagnants = [];
                if (row.original?.tabArticles){
                    accompagnants = row.original?.tabArticles.map(e => e?.price_data?.product_data?.name?.split(" pour ")[1]+"")
                    if (accompagnants.find((e) => row.original?.correspondantInvitation.destinataire.personne.prenom+" "+row.original?.correspondantInvitation.destinataire.personne.nom == e)){
                        accompagnants.splice(accompagnants.indexOf(row.original?.correspondantInvitation.destinataire.personne.prenom+" "+row.original?.correspondantInvitation.destinataire.personne.nom), 1)
                    }
                }
                return accompagnants.join(',\n');
            }
        },

        {
            Header: 'Montant TTC',
            accessor: 'montantHt',
            mminWidth: 120,
            maxWidth: 120,
            Cell: ({ row }) => {
                return (row?.original?.montantHt + (row?.original?.montantHt*row?.original?.tauxTva/100)).toFixed(2).toString().replace(".", ",")+"€"
            }
        },
        {
            Header: 'Frais',
            minWidth: 70,
            maxWidth: 70,
            accessor: 'fraisPaiement',
            Cell: ({ row }) => {
                return row?.original?.fraisPaiement.toString().replace(".", ",")
            }

        },
        {
            Header: 'Id invitation',
            minWidth: 150,
            maxWidth: 150,
            accessor: 'invitation.id',
            Cell: ({ row }) => {
                return <>
                    <span  className="me-2">{row?.original?.invitation.id}</span>
                    <span title="Suivi de l'invitation" className="cursor-pointer" onClick={() => {
                        invitationsProxy.showSuiviInvitation(row?.original?.invitation)
                    }
                    }><SuiviEmailBlack className="h-25px w-25px" /></span>
                </>

            }

        },
        {
            Header: 'Type évènement',
            minWidth: 100,
            maxWidth: 200,
            accessor: 'invitation.type.libelle',

        },
        {
            Header: 'Date évènement',
            minWidth: 150,
            maxWidth: 150,
            accessor: 'invitation.tabPeriodes',
            Cell: ({ row }) => {
                return (Object.values(row?.original?.invitation?.tabPeriodes)[0].debut).split(" ")[0]
            }

        },
        {
            Header: 'nomPrenom',
            accessor: 'correspondantInvitation.destinataire.personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'Prenom',
            accessor: 'correspondantInvitation.destinataire.personne.prenom',
            hidden: true
        },
        {
            Header: 'Nom',
            accessor: 'correspondantInvitation.destinataire.personne.nom',
            hidden: true
        },

    ], []);

    return <>
        <Card>
            <Card.Body>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <button
                        onClick={() => {
                            facturation.showTelechargementFacturation()
                        }}
                        className="btn btn-primary">
                        Télécharger toutes les factures
                    </button>
                </div>
                <BlockUI loading={changeFacture.isLoading}>
                    <MetaTable
                        ref={tableRef}
                        className='mt-5'
                        height={500}
                        url='facture'
                        keys={['facture']}
                        columns={columns}
                        search={searchFilter}
                        // colSearch={colSearch}
                    />
                </BlockUI>
                <div className='d-flex mt-3'>
                    <Button
                        variant='secondary'
                        className='ms-auto'
                        onClick={() => {
                            // tableRef.current?.export();
                            facturation.showExportFacturation(urlExport?.data)
                            // annuaireProxy.showExportAnnuaire(urlExportAnnuaire?.data);
                        }}
                    >
                        <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                        Exporter
                    </Button>
                </div>
            </Card.Body>
        </Card>
        <ExportFacturationModal/>
        <TelechargementFactureModal/>
    </>;
}

export default FacturationView;