import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangeInvitation() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(changeInvitation, {
        onSuccess: (res) => {
            queryClient.setQueryData('invitations', res);
            snapAuth.websocket.send("invitations")

            // queryClient.setQueryData('invitations', res);
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function changeInvitation(invitation) {
    const id = invitation.id;

    if (id != null)
        return api.put(`invitation/${id}`, { json: invitation }).json();
    return api.post(`invitation`, { json: invitation }).json();
}