import { useEffect } from "react";
import { Col, Form, FormGroup, Row, Stack, Button, InputGroup } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import $ from 'jquery';

function ParticipationOptionsForm({ control, name, register, boolPaiementEnLigneIsUtilise, getValues, unregister, errors }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });

    useEffect(() => {
        if (fields.length < 1)
            append({
                designation: '',
                montant: '0',
                id: null,
            });
    }, [fields, append]);

    return <div>
        {fields.map((item, index) => (
            <Stack key={item.id} className={index === 0 ? 'mt-5' : ''}>
                <Row>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Désignation</Form.Label>
                            <Form.Control maxLength={100} className='form-control-solid' {...register(`${name}.${index}.designation`)} />
                            {errors?.[name]?.[index]?.["designation"] && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <FormGroup as={Col}>
                            <Form.Label >Montant</Form.Label>
                            <InputGroup onChange={(e) => {
                                if (e.target.value && e.target.value != 0 && ! getValues(`${name}.${index}.designation`)) {
                                    unregister(`${name}.${index}.designation`)
                                    register(`${name}.${index}.designation`, { required: true })
                                }
                                else if (! getValues(`${name}.${index}.designation`) && e.target.value == 0){
                                    unregister(`${name}.${index}.designation`)
                                    register(`${name}.${index}.designation`)
                                }
                                if (boolPaiementEnLigneIsUtilise){
                                    $("#montantRestant"+index).text(parseFloat(getValues(`${name}.${index}.montant`)*0.981 - 0.5).toFixed(2) + "€ restant")
                                }else {
                                    $("#montantRestant"+index).text("")
                                }
                            }} className='input-group-solid'>
                                <Form.Control type="number" className='form-control-solid' {...register(`${name}.${index}.montant`)} />
                                <InputGroup.Text className='text-muted fw-bold fs-5'>
                                    € TTC
                                </InputGroup.Text>
                                <InputGroup.Text id={"montantRestant"+index} className='text-muted fw-bold fs-5'>

                                </InputGroup.Text>

                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                    {index === fields.length - 1 && <Button
                        variant='link'
                        className='btn-color-info btn-active-color-primary'
                        onClick={() => append({
                            designation: '',
                            montant: '0',
                            id: null,
                        })}
                    >Ajouter une option</Button>}
                    {fields.length > 1 && <Button
                        variant='link'
                        className='btn-color-danger btn-active-color-primary ms-auto'
                        onClick={() => remove(index)}
                    >Enlever l'option</Button>}
                </div>
            </Stack>
        ))}
    </div>;
}

export default ParticipationOptionsForm;