import {useEffect, useState} from "react";
import { Form } from "react-bootstrap";

function RangeSlider({ classContainer, rangeValues, range, setRange }) {


    const handleAnswerUpdate = (
        event
    ) => {
        setRange(rangeValues[parseInt(event.target.value)].valeur);
        setPercentRange(((Object.values(rangeValues).findIndex(item => item.valeur == rangeValues[parseInt(event.target.value)].valeur))/(Object.keys(rangeValues).length-1)))
    }
    const [percentRange, setPercentRange] = useState((Object.values(rangeValues).findIndex(item => item.valeur == rangeValues[parseInt(Object.keys(rangeValues)[Object.values(rangeValues).findIndex(item => item.valeur == range)])].valeur))/(Object.keys(rangeValues).length-1));

    return <div className={classContainer}>
        <div className="position-relative">
            <span  style={{
                width:25,
                height:25,
                transform: "rotate(-45deg)",
                textAlign: "center",
                position:"absolute",
                borderRadius: "50% 50% 50% 0",
                bottom: 30,
                border: "solid 1px var(--primary-color)",
                // backgroundColor: "var(--primary-color)",
                left: "calc("+percentRange*100+"%"+" + ("+(10-(0.2*percentRange*100)-10+(-6+(percentRange * 6)))+"px))"
            }}></span>
            <Form.Range
                value={Object.keys(rangeValues)[Object.values(rangeValues).findIndex(item => item.valeur == range)]}
                min={Object.keys(rangeValues)[0]}
                max={Object.keys(rangeValues)[Object.keys(rangeValues).length-1]}
                step={1}
                onChange={event => handleAnswerUpdate(event)}
                // Here we add the question to the name attribute to identify which key:value
                // pair to update
                // name={question}
            />
        </div>
        <div>
            {Object.values(rangeValues).map((range) => {
                let tauxRangeMap = (Object.values(rangeValues).findIndex(item => item.valeur == rangeValues[parseInt(Object.keys(rangeValues)[Object.values(rangeValues).findIndex(item => item.valeur == range.valeur)])].valeur))/(Object.keys(rangeValues).length-1);
                return <>
                    {/*<span style={{*/}
                    {/*    textAlign: "center",*/}
                    {/*    position: "absolute",*/}
                    {/*    height:8,*/}
                    {/*    width:11,*/}
                    {/*    borderLeft: "solid 1px black",*/}
                    {/*    bottom: -4,*/}
                    {/*    right: "calc("+((Object.values(rangeValues).indexOf(range))/(Object.keys(rangeValues).length-1))*100+"%"+" + ("+(10-(0.2*((Object.values(rangeValues).indexOf(range))/(Object.keys(rangeValues).length-1))*100)-10+(-3+(((Object.values(rangeValues).indexOf(range))/(Object.keys(rangeValues).length-1)) * 6)))+"px))"*/}

                    {/*}}></span>*/}
                    <span style={{
                        textAlign: "center",
                        fontSize: 11,
                        position: "absolute",
                        width: 25,
                        bottom: 34,
                        left: "calc("+tauxRangeMap*100+"%"+" + ("+(10-(0.2*tauxRangeMap*100)-10+(-6+(tauxRangeMap * 6)))+"px))"

                    }}>{range.text}</span></>
            })}

        </div>
    </div>

    {/*<span style={{*/
    }
    {/*    position: "absolute",*/
    }
    {/*    display: "block",*/
    }
    {/*    content: "",*/
    }
    {/*    // transform: "translateX(-50%)",*/
    }
    {/*    width: "100%",*/
    }
    {/*    height: "100%",*/
    }
    {/*    top: 0,*/
    }
    {/*    borderRadius: 100,*/
    }
    {/*    transform: "rotate(-45deg)",*/}
        {/*    background: "blue",*/}
        {/*    zIndex: -1*/}
        {/*}} className="thumb"><span className="thumb-value">1.5</span></span>*/}
}


export default RangeSlider;