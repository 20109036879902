import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Form, Modal} from "react-bootstrap";
import { useSnapshot } from "valtio";
import MetaTable from "@components/MetaTable/MetaTable";
import {useMemo, useState} from "react";
import BlockUI from "@components/BlockUI/BlockUI";
import moment from "moment";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import $ from "jquery";
import Flatpickr from "react-flatpickr";
import { French } from "flatpickr/dist/l10n/fr"
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import smsProxy from "../../../../proxies/sms";
import useSendSmsAsTest from "../../../../requests/communication/sms/useSendSmsAsTest";
import useSendSms from "../../../../requests/communication/sms/useSendSms";
import useResendSms from "../../../../requests/communication/sms/useResendSms";
import useResendSmsForOneCorrespondant from "../../../../requests/communication/sms/useResendSmsForOneCorrespondant";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import auth from "../../../../services/auth";
import useGetCostSms from "../../../../requests/communication/sms/useGetCostSms";

function SendModal() {
    const resendForOneCorresponsant = useResendSmsForOneCorrespondant();
    const queryClient = useQueryClient();
    const smsSnapshot = useSnapshot(smsProxy);
    // const resendForOneCorresponsant = useResendMailForOneCorrespondant();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY HH:mm"));
    const [filterDateEnvoi, setFilterDateEnvoi] = useState("");
    const snapAuth = useSnapshot(auth);
    const getCostSms = useGetCostSms();

    const colSearch = useMemo(() => {
        return {
            'dateEnvoi': filterDateEnvoi,
        };
    }, [filterDateEnvoi]);

    const sendAsTest = useSendSmsAsTest({ id: smsSnapshot.sms?.id }, {
        onSuccess: () => {
            toast.success('Sms envoyé avec succès');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const send = useSendSms({
        id: smsSnapshot.sms?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries(['sms', smsSnapshot.sms?.id, 'correspondants']);
            snapAuth.websocket.send("sms")
            snapAuth.websocket.send(smsSnapshot.sms?.id)
            snapAuth.websocket.send("correspondants")

            toast.success('Le sms a été envoyé !');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        },
    });

    const resend = useResendSms({
        id: smsSnapshot.sms?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries(['sms', smsSnapshot.sms?.id, 'correspondants']);
            snapAuth.websocket.send("sms")
            snapAuth.websocket.send(smsSnapshot.sms?.id)
            snapAuth.websocket.send("correspondants")
            toast.success('L\'envoi du sms a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        },
    });

    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Prénom',
            accessor: 'destinataire.personne.prenom',
            minWidth: 100,
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
            minWidth: 100,
        },
        {
            Header: 'prenomNom',
            accessor: 'destinataire.personne.prenomNom',
            hidden: true,
        },
        {
            Header: 'nomPrenom',
            accessor: 'destinataire.personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'Téléphones',
            accessor: 'destinataire.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.destinataire.personne.tel1 ? row.original.destinataire.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.destinataire.personne.tel2 ? row.original.destinataire.personne.tel2 : "")
                return result;
            }
        },
        {
            Header: 'Prix unitaire sms',
            accessor: 'cout',
            minWidth: 150,
            Cell: ({row}) => {
                return row.original.cout+" €";
            },
        },
        {
            Header: 'dateEnvoi',
            accessor: 'dateEnvoi',
            hidden: true,
        },
        {
            Header: 'État',
            id: 'etat',
            minWidth: 300,
            Cell: ({ row }) => {
                let result = [<span className='text-info'>En attente d'envoi</span>];
                // if (row.original.destinataire?.personne?.email == null)
                //     return <span className='text-warning'>Adresse mail inconnue</span>;
                // if (row.original.destinataire?.personne?.mailIsDesinscrit)
                //     return <span className='text-danger'>Ne souhaite plus recevoir de mail</span>;
                // if (row.original.destinataire?.personne?.mailIsErrone)
                //     return <span className='text-danger'>Adresse mail erronée</span>;
                if (row.original.dateEnvoi !== null )
                    result = [<span className='text-success'>Sms envoyé le {row.original.dateEnvoi}<FontAwesomeIcon icon={faCheck} className='ms-2' /></span>];

                if((row.original.tabEtatsReception).filter(item => item.actif)[0].id == null){
                    result.unshift([<span className='text-info'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }else if ((row.original.tabEtatsReception).filter(item => item.actif)[0].id == 0){
                    result.unshift([<span className='text-info'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }else if ((row.original.tabEtatsReception).filter(item => item.actif)[0].id == 1){
                    result.unshift([<span className='text-success'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }else if ((row.original.tabEtatsReception).filter(item => item.actif)[0].id == 2){
                    result.unshift([<span className='text-warning'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }else if ((row.original.tabEtatsReception).filter(item => item.actif)[0].id == 3){
                    result.unshift([<span className='text-warning'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }else if ((row.original.tabEtatsReception).filter(item => item.actif)[0].id == 5){
                    result.unshift([<span className='text-danger'>{(row.original.tabEtatsReception).filter(item => item.actif)[0].libelle} <br/></span>])
                }

                result.push(<br/>);result.push(<span
                    onClick={() => {
                        BtSwal.fire(
                            {
                                title:"Coût de l'envoi du sms à "+row.original.destinataire.personne.prenom +" "+row.original.destinataire.personne.nom+ " : "+(row.original.cout*100).toFixed(1)+" centimes",
                                showDenyButton: true,
                                confirmButtonText: `Valider`,
                                denyButtonText: `Annuler`,
                            }).then((result) => {
                            if (result.isConfirmed){
                                resendForOneCorresponsant.mutate(row.original.id);
                            }else {
                                BtSwal.fire("Envoi annulé", "", "info");
                            }
                        })
                    }}
                    title="Renvoyer le sms"
                    className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span>)


                return <center>{result}</center>;
            }
        },
    ], []);

    const isLoading = sendAsTest.isLoading || send.isLoading;

    return <Modal
        show={smsSnapshot.sendModal.show}
        onHide={() => smsProxy.sendModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Envoyer le sms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <BlockUI loading={isLoading}>
                <p style={{width: "fit-content"}} className="m-auto alert alert-info mb-2 text-center">Lors de l'envoi, le téléphone 1 sera priorisé, s'il ne s'agit pas d'un numéro de téléphone portable, le téléphone 2 sera utilisé.</p>
                <div className='d-flex justify-content-center'>
                    <Button
                        variant='secondary'
                        onClick={async () => {
                            const cout = await getCostSms.mutateAsync({
                                id: smsSnapshot.sms.id,
                                isSeulementEnAttente: true
                            })
                            BtSwal.fire({
                                title: "Le prix de l'envoi pour les destinataires en attente est de "+cout.cout.montant+ "€ pour "+cout.cout.nbSms+" sms. \nConfirmez vous l'envoi ?",
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Non`,
                                icon: 'info',
                            }).then((res) => {
                                if (res.isConfirmed){
                                    send.mutate()
                                }
                            })
                        }}
                    >
                        Envoyer aux destinataires en attente</Button>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-3'>
                    <Button
                        onClick={
                            () => {
                                if ($("#divDateEnvoiSms").hasClass('d-none')){
                                    $("#divDateEnvoiSms").removeClass('d-none')
                                }else {
                                    $("#divDateEnvoiSms").addClass('d-none')
                                }
                            }
                        }
                        className="me-2"
                        style={{height : "fit-content"}}
                        variant='primary'>
                        Programmer l'envoi
                    </Button>
                    <div className={"d-none"}
                         id={"divDateEnvoiSms"}>
                        <Flatpickr
                            className={"form-control mt-2"}
                            display={"hidden"}
                            defaultValue={dateEnvoi}
                            onChange={date => {
                                setDateEnvoi(moment(date[0]).format("DD/MM/YYYY HH:mm"));
                            }}
                            options={{
                                locale: French,
                                dateFormat: 'd/m/Y à H:i',
                                enableTime: true,
                            }}
                        />
                        <Button className={"mt-2 m-auto"}
                                style={{width: "fit-content"}}
                                onClick={async () => {
                                    const cout = await getCostSms.mutateAsync({
                                        id: smsSnapshot.sms.id,
                                        isSeulementEnAttente: true
                                    })
                                    BtSwal.fire({
                                        title: "Le prix de l'envoi programmé pour les destinataires en attente est de " + cout.cout.montant + "€ pour " + cout.cout.nbSms + " sms. \nConfirmez vous l'envoi ?",
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Non`,
                                        icon: 'info',
                                    }).then((res) => {
                                        if (res.isConfirmed) {
                                            send.mutate()
                                        }
                                    })
                                }}
                        >Valider</Button>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <Button
                        style={{width: "fit-content"}}
                        onClick={async () => {
                            const cout = await getCostSms.mutateAsync({
                                id: smsSnapshot.sms.id,
                                isSeulementEnAttente: false
                            })
                            BtSwal.fire({
                                title: "Le prix de l'envoi programmé pour les destinataires en attente est de " + cout.cout.montant + "€ pour " + cout.cout.nbSms + " sms. \nConfirmez vous l'envoi ?",
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Non`,
                                icon: 'info',
                            }).then((res) => {
                                if (res.isConfirmed) {
                                    resend.mutate()
                                }
                            })
                        }}
                        variant='danger'>Renvoyer à tous les destinataires</Button>
                </div>
                <div className='mt-5'>
                    <h3>Liste des destinataires</h3>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    {filterDateEnvoi !== "NULL" ? <Form.Check
                            className="mt-5"
                            checked={false}
                            type={"checkbox"}
                            id={`default-radio`}
                            label={`Afficher seulement les destinataires en attente`}
                            onClick={() => setFilterDateEnvoi("NULL")
                            }
                        />
                        :
                        <Form.Check
                            className="mt-5"
                            checked={true}
                            type={"checkbox"}
                            id={`default-radio`}
                            label={`Afficher seulement les destinataires en attente`}
                            onClick={() => setFilterDateEnvoi("")
                            }
                        />
                    }
                    <MetaTable
                        className='mt-3'
                        height={400}
                        url={`sms/${smsSnapshot.sms?.id}/correspondants`}
                        keys={['sms', smsSnapshot.sms?.id, 'correspondants']}
                        columns={columns}
                        search={debouncedSearch}
                        colSearch={colSearch}
                    />
                </div>
            </BlockUI>
        </Modal.Body>
    </Modal>;
}

export default SendModal;