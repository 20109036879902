import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Alert, Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import React, {useEffect, useState} from "react";
import useGetDestinataireValidationMessage
    from "../../../requests/parametres/destinataireValidation/useGetDestinataireValidationMessage";
import PersonneSelection from "../../personne/PersonneSelection/PersonneSelection";
import SupportDestinataireValidationMessageSelection
    from "../SupportDestinataireValidationMessage/SupportDestinataireValidationMessageSelection";
import useChangeDestinataireValidationMessage
    from "../../../requests/parametres/destinataireValidation/useChangeDestinataireValidationMessage";
import useDeleteValidationMessage from "../../../requests/parametres/destinataireValidation/useDeleteValidationMessage";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function ExpediteurSettings() {
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const expediteurs = useGetDestinataireValidationMessage(debouncedFilter);
    const queryClient = useQueryClient();
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const deleteDestinataire = useDeleteValidationMessage();
    const snapAuth = useSnapshot(auth);
    const [update, setUpdate] = useState(0);
    const changeDestinataire = useChangeDestinataireValidationMessage({
        onSuccess: () => {
            queryClient.invalidateQueries('destinataireValidationMessage');
            snapAuth.websocket.send("destinataireValidationMessage")
            setHiddenAdd(false)
            setActiveKey(null);
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });

    function onSave(data) {
        changeDestinataire.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
        }
    }

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [expediteurs.isSuccess])

    if (expediteurs.isError)
        return <Alert variant='danger'>{expediteurs.error?.message}</Alert>;

    if (expediteurs.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <Accordion activeKey={activeKey} className='mt-7'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant='secondary'
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            setHiddenEditLines(true);
                            setUpdate(update+1);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un destinataire
                    </Button>
                </div>
            </div>
        </div>
        {expediteurs.data?.data?.map(exp => {
            return <div hidden={hiddenEditLines} className='bg-light my-2 p-3 rounded' key={exp.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {exp.personne.prenom+" "+exp.personne.nom}<span className='fw-bolder text-info'> ({exp.personne.email})</span>
                    </div>
                    <button
                        onClick={() => toggleAccordionKey(exp.id)}
                        className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                        onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer le destinataire '+exp.personne.prenom + ' '+exp.personne.nom+' ?' ,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteDestinataire.mutate(exp.id)
                                BtSwal.fire('Le destinataire a été supprimé!', '', 'success')
                                setHiddenAdd(false)
                            } else if (result.isDenied) {
                                BtSwal.fire('Le destinataire n\'a pas été supprimé', '', 'info')
                            }
                        })}
                        className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <Accordion.Collapse eventKey={exp.id}>
                    <div className='mt-3'>
                        <div className='separator' />
                        <ExpediteurForm update={update} expediteur={exp} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-10'>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10'>
                    <ExpediteurForm update={update} onSave={onSave} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey} />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function ExpediteurForm({ expediteur, onSave, setHiddenEditLines, setHiddenAdd, toggleAccordionKey, update }) {
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: expediteur ?? {
            personne : null,
            tabSupports : null
        },
    });

    useEffect(() => {
        reset(expediteur)
    }, [expediteur, reset, update])

    function handleSubmitSave(data) {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }

        onSave(data)
    }

    return <div className='mt-5 mx-10'>
        <Form.Group className='mt-5'>
            <Form.Label>Choisir le destinataire</Form.Label>
            <PersonneSelection control={control} name='personne' rules={{ required: true }} />
            {errors.personne && <Form.Text className='text-danger'>Ce champ est requis.</Form.Text>}
        </Form.Group>
        <Form.Group className='mt-5'>
            <Form.Label>Choisir des supports de communication qu'il peut valider</Form.Label>
            <SupportDestinataireValidationMessageSelection control={control} name='tabSupports' rules={{ required: true }} />
            {errors.tabSupports && <Form.Text className='text-danger'>Ce champ est requis.</Form.Text>}
        </Form.Group>
        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{expediteur?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {expediteur?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }
        </div>
    </div>;
}

export default ExpediteurSettings;