import { proxy } from "valtio";

const smsProxy = proxy({
    sms: null,
    tabIdsDestinataires:null,
    destinataireIsChecked:false,
    destinataireAlertIsAdd: false,
    editModal: {
        show: false,
        step: 0,
        loading: false,
    },
    sendModal: {
        show: false,
    },
    relanceModal: {
        show:false,
    },
    responseModal: {
        show:false,
    },
    statModal: {
        show:false,
    },
    tarifsModal: {
        show:false,
    },
    showEditSms: (sms) => {
        smsProxy.sms = sms;
        smsProxy.sendModal.show = false;
        smsProxy.relanceModal.show = false;
        smsProxy.responseModal.show = false;
        smsProxy.tarifsModal.show = false;
        smsProxy.tabIdsDestinataires = null;
        smsProxy.destinataireIsChecked = false;
        smsProxy.editModal = {
            show: true,
            step: 0,
            loading: false,
        };
    },
    showSendSms: (sms) => {
        smsProxy.sms = sms;
        smsProxy.editModal.show = false;
        smsProxy.relanceModal.show = false;
        smsProxy.responseModal.show = false;
        smsProxy.tarifsModal.show = false;
        smsProxy.sendModal = {
            show: true,
        };
    },
    showReponseSms: (sms) => {
        smsProxy.sms = sms;
        smsProxy.editModal.show = false;
        smsProxy.relanceModal.show = false;
        smsProxy.sendModal.show = false;
        smsProxy.tarifsModal.show = false;
        smsProxy.responseModal = {
            show: true,
        };
    },
    showRelanceSms: (sms) => {
        smsProxy.sms = sms;
        smsProxy.editModal.show = false;
        smsProxy.sendModal.show = false;
        smsProxy.responseModal.show = false;
        smsProxy.tarifsModal.show = false;
        smsProxy.relanceModal = {
            show: true,
        };
    },
    showTarifsSms: () => {
        smsProxy.editModal.show = false;
        smsProxy.sendModal.show = false;
        smsProxy.responseModal.show = false;
        smsProxy.relanceModal.show = false;
        smsProxy.tarifsModal = {
            show: true,
        };
    },
    showStatSms: (sms) => {
        smsProxy.sms = sms;
        smsProxy.sendModal.show = false;
        smsProxy.responseModal.show = false;
        smsProxy.statModal = {
            show: true,
        };
    },
})

export default smsProxy;