import {Button, Card, Dropdown} from "react-bootstrap";
import SearchBar from "../../components/SearchBar/SearchBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useMemo} from "react";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import MetaTable from "../../components/MetaTable/MetaTable";
import {useDebounce} from "use-debounce";
import {useForm} from "react-hook-form";
import speedBusinessProxy from "../../proxies/speedBusiness";
import EditModal from "./EditModal/EditModal";

export default function SpeedBusinessView(){
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const colSearch =  {
    };
    const [searchFilter] = useDebounce(search, 300);

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showEditSpeedBusiness(row.current)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell: ({ value, row }) => {
                return value
            },
        },
        {
            Header: 'Nom',
            accessor: 'nom',
            minWidth: 300,
            noWrap: true
        }
    ], []);
    return <Card>
        <Card.Body>
            <div className='d-flex flex-stack flex-wrap'>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <div className='mb-5'>
                    <Button variant='secondary' onClick={() => {
                        speedBusinessProxy.showEditSpeedBusiness("");
                    }}>
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Générer les rencontres
                    </Button>
                </div>
            </div>
            <div>
                <MetaTable
                    className='mt-5'
                    height={500}
                    url='rencontreSpeedBusiness'
                    keys={['rencontreSpeedBusiness']}
                    columns={columns}
                    search={searchFilter}
                    colSearch={colSearch}
                />
            </div>
            <EditModal/>
        </Card.Body>
    </Card>;

}