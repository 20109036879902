import {useCallback, useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation } from 'react-router';
import styled from 'styled-components';
import Header from './Header/Header';
import SideMenu from './SideMenu/SideMenu';
import UserProfil from './UserProfil/UserProfil';
import {Link, useParams} from 'react-router-dom';
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import {useQueryClient} from "react-query";
import {URLWebsocket} from "../../config";
import mailsProxy from "../../proxies/mails";
import {v4 as uuidv4} from "uuid";
import invitationsProxy from "../../proxies/invitations";
import smsProxy from "../../proxies/sms";
import publicationMobileProxy from "../../proxies/publicationMobile";

const Backdrop = styled.div`
  position: fixed;
  z-index: 109;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

function IndexView() {
    const snapAuth = useSnapshot(auth);
    const [showDrawer, setShowDrawer] = useState(false);
    const isDrawer = useMediaQuery({ maxWidth: 992 }, undefined, () => setShowDrawer(false));
    const snapMail = useSnapshot(mailsProxy);
    const snapInvitation = useSnapshot(invitationsProxy);
    const snapSms = useSnapshot(smsProxy);
    const snapPublication = useSnapshot(publicationMobileProxy);
    const queryClientb = useQueryClient();
    const [init, setInit] = useState(0);
    const [currentId, setCurrentId] = useState(0);
    const [currentIdInvitation, setCurrentIdInvitation] = useState(0);
    const [currentIdSms, setCurrentIdSms] = useState(0);
    const [currentIdPublication, setCurrentIdPublication] = useState(0);
    const [reloadWebSocket, setReloadWebSocket] = useState(0);
    const versionApp = 1.106;

    useEffect(() => {
        let websocket = new WebSocket(URLWebsocket)
        let idConnexion = null;


        websocket.addEventListener("close", (event) => {
            setTimeout(function (){
                setReloadWebSocket(uuidv4());
                auth.websocket.send = (cache) => {
                    console.log("ws hs")
                };
            },3000)

        });

        websocket.onopen = (ev) => {
            console.log('connected')
            auth.websocket.send = (cache) => {
                if (cache?.mail){
                    cache = {
                        typeWebProxySocket : cache?.typeWebProxySocket,
                        mail: {
                            id: cache.mail.id
                        },
                        editModal: cache?.editModal
                    }
                }else if (cache?.invitation){
                    cache = {
                        typeWebProxySocket : cache?.typeWebProxySocket,
                        invitation: {
                            id: cache.invitation.id
                        },
                        editModal: cache?.editModal
                    }
                }else if (cache?.publicationMobile){
                    cache = {
                        typeWebProxySocket : cache?.typeWebProxySocket,
                        publicationMobile: {
                            id: cache.publicationMobile.id
                        },
                        editModal: cache?.editModal
                    }
                }else if (cache?.sms){
                    cache = {
                        typeWebProxySocket : cache?.typeWebProxySocket,
                        sms: {
                            id: cache.sms.id
                        },
                        editModal: cache?.editModal
                    }
                }
                websocket.send(btoa(JSON.stringify({
                    action : "clearCache",
                    idConnexion : idConnexion,
                    idAgence : snapAuth.agence.id,
                    cache : cache
                })))
            };
        }
        websocket.onmessage = (event) => {
            let data  = (JSON.parse(atob(event.data)));
            if (data?.response?.version && (data?.response?.version > versionApp)){
                window.location.reload();
            }
            if (data?.action == "connexion" && data?.response?.idConnexion){
                idConnexion = data?.response?.idConnexion
                websocket.send(btoa(JSON.stringify({
                    action : "identification",
                    idConnexion : data?.response?.idConnexion,
                    idUtilisateur : snapAuth.utilisateur.id,
                    idAgence : snapAuth.agence.id
                })))
            }
            else if(data?.action == "identification"){
                websocket.send(btoa(JSON.stringify({
                    action : "clearCache",
                    idConnexion : idConnexion,
                    idAgence : snapAuth.agence.id,
                    cache : "getProxies"
                })))
            }
            else if(data?.action == "clearCache"){
                if (data?.response?.cache == "getProxies"){
                    //todo si tout est cassé à décommenter

                    // auth.tabIdMailVerrou = [];
                    setInit(uuidv4())
                    updateTabId(data)
                }else if (data?.response?.cache?.typeWebProxySocket == "email" || data?.response?.cache?.typeWebProxySocket == "invitation" || data?.response?.cache?.typeWebProxySocket == "sms" || data?.response?.cache?.typeWebProxySocket == "publication"){
                    //todo mettre a jour tout les tabID
                    updateTabId(data)
                }
                else {
                    queryClientb.invalidateQueries(data?.response?.cache);
                }
            }
        }

        return () => {
            websocket.close()
        }}, [queryClientb, snapAuth.agence.id, snapAuth.utilisateur.id, reloadWebSocket])



    function updateTabId(data){
        if (data?.response?.cache?.mail?.id && data?.response?.cache?.editModal?.show && snapAuth.tabIdMailVerrou.indexOf(data?.response?.cache?.mail?.id) === -1){
            auth.tabIdMailVerrou.push(data?.response?.cache?.mail?.id);
        }else if (data?.response?.cache?.mail?.id && !data?.response?.cache?.editModal?.show){
            setCurrentId(data?.response?.cache?.mail?.id);
        }
        if (data?.response?.cache?.invitation?.id && data?.response?.cache?.editModal?.show && snapAuth.tabIdInvitationVerrou.indexOf(data?.response?.cache?.invitation?.id) === -1){
            auth.tabIdInvitationVerrou.push(data?.response?.cache?.invitation?.id);
        }else if (data?.response?.cache?.invitation?.id && !data?.response?.cache?.editModal?.show){
            setCurrentIdInvitation(data?.response?.cache?.invitation?.id);
        }
        if (data?.response?.cache?.publicationMobile?.id && data?.response?.cache?.editModal?.show && snapAuth.tabIdPublicationVerrou.indexOf(data?.response?.cache?.publicationMobile?.id) === -1){
            auth.tabIdPublicationVerrou.push(data?.response?.cache?.publicationMobile?.id);
        }else if (data?.response?.cache?.publicationMobile?.id && !data?.response?.cache?.editModal?.show){
            setCurrentIdPublication(data?.response?.cache?.publicationMobile?.id);
        }
        if (data?.response?.cache?.sms?.id && data?.response?.cache?.editModal?.show && snapAuth.tabIdSmsVerrou.indexOf(data?.response?.cache?.sms?.id) === -1){
            auth.tabIdSmsVerrou.push(data?.response?.cache?.sms?.id);
        }else if (data?.response?.cache?.sms?.id && !data?.response?.cache?.editModal?.show){
            setCurrentIdSms(data?.response?.cache?.sms?.id);
        }
    }
    // Retract drawer when route change
    const location = useLocation().pathname;
    const {agence} = useParams();
    useEffect(() => {
        setShowDrawer(false);
    }, [location]);

    //updateTabId remove modal hide
    useEffect(() => {
        if (currentId !== 0){
            auth.tabIdMailVerrou = snapAuth.tabIdMailVerrou.filter(i => i !== currentId);
            //reset id
            setCurrentId(0);
        }
    }, [currentId])
    useEffect(() => {
        if (currentIdInvitation !== 0){
            auth.tabIdInvitationVerrou = snapAuth.tabIdInvitationVerrou.filter(i => i !== currentIdInvitation);
            //reset id
            setCurrentIdInvitation(0);
        }
    }, [currentIdInvitation])
    useEffect(() => {
        if (currentIdPublication !== 0){
            auth.tabIdPublicationVerrou = snapAuth.tabIdPublicationVerrou.filter(i => i !== currentIdPublication);
            //reset id
            setCurrentIdPublication(0);
        }
    }, [currentIdPublication])
    useEffect(() => {
        if (currentIdSms !== 0){
            auth.tabIdSmsVerrou = snapAuth.tabIdSmsVerrou.filter(i => i !== currentIdSms);
            //reset id
            setCurrentIdSms(0);
        }
    }, [currentIdSms])

    useEffect(() => {
        if (snapMail.mail && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapMail,
                typeWebProxySocket : "email"
            })
        }
        if (snapInvitation.invitation && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapInvitation,
                typeWebProxySocket : "invitation"
            })
        }
        if (snapPublication.publicationMobile && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapPublication,
                typeWebProxySocket : "publication"
            })
        }
        if (snapSms.sms && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapSms,
                typeWebProxySocket : "sms"
            })
        }
    }, [init, snapAuth.utilisateur.id])

    //ws pour la mise a jour des proxies
    useEffect(() => {
        if (snapMail.mail && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapMail,
                typeWebProxySocket : "email"
            })
        }
    }, [snapAuth.websocket, snapMail, snapAuth.utilisateur.id])

    useEffect(() => {
        if (snapInvitation.invitation && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapInvitation,
                typeWebProxySocket : "invitation"
            })
        }
    }, [snapAuth.websocket, snapInvitation, snapAuth.utilisateur.id])

    useEffect(() => {
        if (snapPublication.publicationMobile && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapPublication,
                typeWebProxySocket : "publication"
            })
        }
    }, [snapAuth.websocket, snapPublication, snapAuth.utilisateur.id])

    useEffect(() => {
        if (snapSms.sms && snapAuth.websocket.send){
            snapAuth.websocket.send({
                ...snapSms,
                typeWebProxySocket : "sms"
            })
        }
    }, [snapAuth.websocket, snapSms, snapAuth.utilisateur.id])

    
    return <>
        {(isDrawer && showDrawer) && <Backdrop onClick={() => setShowDrawer(false)} />}
        <div className='page d-flex flex-row flex-column-fluid'>
            <div className={`aside ${isDrawer ? 'drawer drawer-start w-250px' : ''} ${isDrawer && showDrawer ? 'drawer-on' : ''}`}>
                <div className='aside-menu flex-column-fluid'>
                    <div className='hover-scroll-overlay-y px-2 my-5 my-lg-5'>
                        <SideMenu />
                    </div>
                </div>
                <div className='aside-footer flex-column-auto pb-5'>
                    <UserProfil />
                </div>
            </div>
            <div className='wrapper d-flex flex-column flex-row-fluid'>
                <div className='header align-items-stretch'>
                    <div className='header-brand'>
                        <Link to={"/" + agence + '/accueil'}>
                            <div className="bg-white p-2 rounded-2">
                                <img src={snapAuth.srcLogo} className='h-60px logo' />
                            </div>
                        </Link>
                        <div style={{fill : getComputedStyle(document.body).getPropertyValue('--color-on-primary-background')}} className='d-flex align-items-center d-lg-none ms-n3 me-1' onClick={() => setShowDrawer(!showDrawer)}>
                            <span className='svg-icon svg-icon-1'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                    <path d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'></path>
                                    <path opacity='0.3' d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z' fill='black'></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className='toolbar'>
                        <Header />
                    </div>
                </div>
                <div className='content d-flex flex-column flex-column-fluid'>
                    <Container fluid>
                        <Outlet />
                    </Container>
                </div>
                <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
                    <div className='container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between'>
                        <div className='text-dark order-2 order-md-1'>
                            <span className='text-muted fw-bold me-1'>{new Date().getFullYear()}©</span>
                            <a href='https://rgdev.fr' target='_blank' rel='noreferrer' className='text-gray-800 text-hover-primary'>RG Développement</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default IndexView;