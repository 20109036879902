import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useEditSujet(idAgence, corps, objet, personne, categorie, id, vues, tabEtats, datePublication, isReadOnly) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    let jsonCreationSujet = {
        idAgence: idAgence,
        // isPublie: isPublie,
        tabEtats : tabEtats,
        isLectureSeule : isReadOnly,
        nbVues: vues,
        objet: objet,
        corps: corps,
        datePublication: datePublication,
        auteur: {
            personne : personne
        },
        categorie: categorie,
        id : parseInt(id)
    }


    return useMutation((tabEtats) => {
        return api.put('sujetForum/'+id, {
            json:  {...jsonCreationSujet,
                tabEtats : tabEtats
            },
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries("sujetForumByCategories"+categorie.libelle);
            queryClient.invalidateQueries("sujetForum");
            queryClient.invalidateQueries(id);
            snapAuth.websocket.send("sujetForum")
            snapAuth.websocket.send("sujetForumByCategories"+categorie.libelle)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}